import axios from "axios";
import { GET_COUPON, ADD_COUPON, EDIT_COUPON, GET_COUPONS } from "./types";
import { userToken } from "./user";
import { ICoupon } from "../../server/models/coupons";
import { DispatchType, GetStateType, ActionType } from ".";
export type CouponStateType = {
  coupons: ICoupon[];
  coupon: ICoupon;
};
export const couponInitialState: CouponStateType = {
  coupons: [],
  coupon: null,
};

export const getCoupon = (id: string) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const coupon = getState().coupon.coupons.find((coupon) => coupon._id === id);
  dispatch({ type: GET_COUPON, payload: coupon });
};

export const getAllCoupons = () => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  axios
    .get("/api/coupons", userToken(getState))
    .then((res) => {
      dispatch({
        type: GET_COUPONS,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log("I got an error in get products", error);
    });
};

export const createCoupon = (
  coupon: ICoupon,
  cb: (res: any, error: any) => any
) => (dispatch: DispatchType, getState: GetStateType) => {
  axios
    .post("/api/coupons", coupon, userToken(getState))
    .then((res) => {
      dispatch({
        type: ADD_COUPON,
        payload: res.data,
      });
      if (cb && typeof cb === "function") {
        cb(res, null);
      }
    })
    .catch((error) => {
      if (cb && typeof cb === "function") {
        cb(null, error);
      }
      console.log("error posting a coupon", error);
    });
};

export const editCoupon = (
  coupon: ICoupon,
  cb: (res: any, error: any) => any
) => (dispatch: DispatchType, getState: GetStateType) => {
  axios
    .patch(`/api/coupons/${coupon._id}`, coupon, userToken(getState))
    .then((res) => {
      dispatch({ type: EDIT_COUPON, payload: coupon });
      if (cb && typeof cb === "function") {
        cb(res, null);
      }
    })
    .catch((error) => {
      console.log("error editing coupon", error);
      if (cb && typeof cb === "function") {
        cb(null, error);
      }
    });
};

export default (state = couponInitialState, action: ActionType) => {
  switch (action.type) {
    case GET_COUPONS:
      return {
        ...state,
        coupons: action.payload,
      };

    case GET_COUPON:
      return {
        ...state,
        coupon: action.payload,
      };
    case ADD_COUPON:
      return {
        ...state,
        coupons: [action.payload, ...state.coupons],
      };
    case EDIT_COUPON:
      return {
        ...state,
        coupons: state.coupons.map((coupon) =>
          coupon._id === action.payload._id ? action.payload : coupon
        ),
      };

    default:
      return state;
  }
};
