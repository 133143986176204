import axios from "axios";

import { toast } from "react-toastify";
import { userCheckOut } from "./user";
import {
  CHECKOUT_ERROR,
  CHECKOUT_LOADING,
  //CHECKOUT_SUCCESS,
  CHECKOUT_COUPON_CHECK,
  //CLEAR_CART,
  CHECKOUT_INTENT,
  PAYPAL_CHECKOUT,
  GET_TOTAL_PASS,
  GET_TOTAL_FAIL,
} from "./types";
import { DispatchType, GetStateType, ActionType } from ".";
import { CartItemType } from "./cart";
//import { clearCart } from "./cart";
export type CheckoutStateType = {
  verifiedCart: boolean;
  charge: any;
  checkout: any;
  message: string;
  loading: boolean;
  couponTotal: number;
  couponDiscount: number;
  couponCode: string;
  intentKey: string;
  verifiedTotal: number;
};

export const checkoutInitialState: CheckoutStateType = {
  verifiedCart: false,
  checkout: {},
  charge: {},
  message: "",
  loading: false,
  couponTotal: 0,
  couponDiscount: 0,
  couponCode: "",
  intentKey: "",
  verifiedTotal: 0,
};

// cart,
// stripeoken,
// shippingId,
// shippingToken,
// addressOverRide,
// address,
// name,
// userId,
// email

export const addCoupon = (
  coupon: string,
  shippingToken: number,
  cart: CartItemType[],
  userId: string
) => async (dispatch: DispatchType) => {
  axios
    .post("/api/coupons/getnewtotal", { coupon, shippingToken, cart, userId })
    .then((res) => {
      toast.success(`Whoo you just saved $${res.data.couponDiscount}`, {
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      dispatch({
        type: CHECKOUT_COUPON_CHECK,
        payload: { coupon: res.data, couponCode: coupon },
      });
    })
    .catch((error) => {
      toast.error(
        "I just hacked the main frame and I STILL cant get that coupon to work..... try another code",
        {
          position: "top-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      // console.log("I got an error in get couponTotal", error);
    });
};

export const getCartTotal = (
  cartInfo: CartItemType[],
  cb: (res: any) => any
) => async (dispatch: DispatchType) => {
  axios
    .post("/api/checkout/get-cart-total", cartInfo)
    .then((res) => {
      //console.log("this is the res", res.data);
      dispatch({
        type: GET_TOTAL_PASS,
        payload: {
          total: res.data.totalWithShipping,
          kitOptions: res.data.kitOptions,
        },
      });

      if (typeof cb === "function") {
        return cb(res.data);
      }
    })
    .catch((error) => {
      toast.error(
        `There was a error verifying your cart please reload the page and let us know about it we can help`,
        {
          position: "top-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      dispatch({
        type: GET_TOTAL_FAIL,
      });
    });
};

export const checkout = (checkout: any, cb: (res: any) => any) => async (
  dispatch: DispatchType
) => {
  dispatch(setCheckoutLoading());
  return axios
    .post("/api/checkout/start-checkout", checkout)
    .then((res) => {
      if (res.data.message === "intent Created") {
        dispatch({
          type: CHECKOUT_INTENT,
          payload: {
            message: res.data.message,
            intentKey: res.data.intentKey,
            checkout,
          },
        });
      } else {
        dispatch({
          type: PAYPAL_CHECKOUT,
          payload: {
            message: res.data.message,
            orderId: res.data.order,
            total: res.data.totalWithShipping,
            checkout,
          },
        });
      }
      userCheckOut();
      if (typeof cb === "function") {
        return cb(res.data);
      }

      // dispatch({ type: CLEAR_CART });
    })
    .catch((error) => {
      console.log("there was an error checking out", { ...error });
      // if (error && error.response.data) {
      //   const errorMessage = (() => {
      //     switch (error.response.data.error.type) {
      //       case "StripeCardError":
      //         // A declined card error
      //         return error.response.data.error.message; // => e.g. "Your card's expiration year is invalid."
      //       case "StripeRateLimitError":
      //         // Too many requests made to the API too quickly
      //         return ">.< To many requests right now try to checkout again in a bit.";
      //       default:
      //         // Handle any other types of unexpected errors
      //         return "Something has gone wrong on our end. Submit a ticket or hop in discord and let us know if you could.";
      //     }
      //   })();
      //   toast.error(`There was an error charging your card. ${errorMessage}`, {
      //     position: "top-center",
      //     autoClose: 9900,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //   });

      //   dispatch({
      //     type: CHECKOUT_ERROR,
      //     payload: {
      //       message: error.response.data.message,
      //       error: error.response.data.error,
      //       checkout,
      //     },
      //   });
      // }
    });
};
export const finishCheckout = (
  orderThatFinished: any,
  cb: (res: any) => any
) => async () => {
  return axios
    .post("/api/checkout/finishorder", orderThatFinished)
    .then((res) => {
      userCheckOut();
      if (typeof cb === "function") {
        return cb(res.data);
      }
    })
    .catch((error) => {
      console.log("there was an error checking out", { ...error });
    });
};

export const setCheckoutLoading = () => ({ type: CHECKOUT_LOADING });

export default (state = checkoutInitialState, action: ActionType) => {
  switch (action.type) {
    case CHECKOUT_INTENT:
      return {
        ...state,
        message: action.payload.message,
        intentKey: action.payload.intentKey,
        loading: false,
      };
    case PAYPAL_CHECKOUT:
      return {
        ...state,
        message: action.payload.message,
        orderId: action.payload.orderId,
        total: action.payload.total,
        loading: false,
      };
    case CHECKOUT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CHECKOUT_COUPON_CHECK:
      return {
        ...state,
        couponTotal: action.payload.coupon.total / 100,
        couponDiscount: action.payload.coupon.couponDiscount,
        couponCode: action.payload.couponCode,
      };
    case CHECKOUT_ERROR:
      return {
        ...state,
        checkout: action.payload.checkout,
        message: action.payload.message,
        error: action.payload.error,
        loading: false,
      };
    case GET_TOTAL_PASS:
      return {
        ...state,
        verifiedTotal: action.payload.total,
        verifiedCart: true,
      };
    case GET_TOTAL_FAIL:
      return {
        ...state,
        verifiedCart: false,
      };

    default:
      return state;
  }
};
