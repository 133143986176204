import React, { Component } from "react";
import ButtonContent from "../buttonContent/buttonContent.js";

class ButtonText extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <ButtonContent {...this.props} />;
  }
}

export default ButtonText;
