import React, { Component } from "react";
import ButtonContent from "../buttonContent/buttonContent";
import { Link } from "react-router-dom";

import "../button.sass";
//import { history } from "../../../../store";

class ButtonLink extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        {this.props.path ? (
          <Link to={this.props.path}>
            <ButtonContent {...this.props} />
          </Link>
        ) : (
          <a rel="noopener" href={this.props.src} target="_blank">
            <ButtonContent {...this.props} />
          </a>
        )}
      </>
    );
  }
}

export default ButtonLink;
