import React, { Component } from "react";
import ButtonContent from "../buttonContent/buttonContent.js";
import Modal from "../../modal/modal";
import { Transition } from "react-transition-group";

import "../button.sass";

class ButtonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  toggleModal = () => {
    this.setState(
      {
        open: !this.state.open,
      },
      () => this.removeScroll(this.state.open)
    );
  };
  removeScroll = (shouldLock) => {
    if (shouldLock) {
      document.body.classList.add("modal-open");
    } else if (!shouldLock) {
      document.body.classList.remove("modal-open");
    } else {
      return;
    }
  };
  render() {
    const duration = 300;
    const defaultStyle = {
      transition: `opacity ${duration}ms ease-in-out`,
      opacity: 0,
    };

    const transitionStyles = {
      entering: { opacity: 0 },
      entered: { opacity: 1 },
      exiting: { opacity: 0 },
      exited: { opacity: 0 },
    };
    return (
      <>
        <ButtonContent {...this.props} toggleModal={this.toggleModal} />
        <Transition
          in={this.state.open}
          timeout={duration}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          {(state) => {
            return (
              <div
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}
              >
                <Modal
                  modalProp={this.props.modalProp}
                  modalType={this.props.modalType}
                  ModalContent={this.props.ModalContent}
                  modalColor={this.props.modalColor}
                  open={this.state.open}
                  toggleModal={this.toggleModal}
                  {...this.props}
                />
              </div>
            );
          }}
        </Transition>
      </>
    );
  }
}

export default ButtonModal;
