import axios from "axios";
import { v1 as uuidv1 } from "uuid";
import { toast } from "react-toastify";
import {
  ADD_ITEM_TO_CART,
  ADD_MULTIPLE_ITEMS_TO_CART,
  REMOVE_ITEM_FROM_CART,
  UPDATE_ITEM_IN_CART,
  CLEAR_CART,
  CART_INSTOCK_CHECK_PASS,
  CART_INSTOCK_CHECK_FAIL,
  GET_TOTAL_PASS,
} from "./types";

import { DispatchType, ActionType } from ".";
export type CartItemType = {
  id: string;
  qty: number;
  kitId: string;
  mainProductId: string;
};
export type ItemsNotInStockType = {
  product: string;
  amtInStock: number;
  amtRequired: number;
  amtShort: number;
};
export type PickedOptionsType = {
  optionId: string;
  toRemove: number;
  freeKitItem: boolean | any;
  kitId: string;
};
export type CartStateType = {
  cart: CartItemType[];
  allInStock: boolean;
  itemsNotInStock: ItemsNotInStockType[];
  pickedOptions: PickedOptionsType[];
};

export const cartInitialState: CartStateType = {
  cart: [],
  allInStock: false,
  itemsNotInStock: [],
  pickedOptions: [],
};

export const checkStockLevels = (
  cart: CartItemType[],
  cb: (res: any) => any
) => async (dispatch: DispatchType) => {
  return axios
    .post("/api/checkout/check-stock", { cart })
    .then((res) => {
      //console.log(res);
      if (res.data.allInStock) {
        dispatch({
          type: CART_INSTOCK_CHECK_PASS,
          payload: {
            cart,
            ...res.data,
          },
        });
      } else {
        dispatch({
          type: CART_INSTOCK_CHECK_FAIL,
          payload: {
            cart,
            ...res.data,
          },
        });
      }
      if (typeof cb === "function") {
        return cb(res.data);
      }
    })
    .catch((error) => {
      console.log("there was an error checking stock level", { ...error });
    });
};

export const addItemToCart = (cartItem: CartItemType) => async (
  dispatch: DispatchType
) => {
  dispatch({
    type: ADD_ITEM_TO_CART,
    payload: cartItem,
  });
  toast.success("Added To Your Cart!", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const removeItemFromCart = (id: string) => async (
  dispatch: DispatchType
) => {
  dispatch({
    type: REMOVE_ITEM_FROM_CART,
    payload: id,
  });
};

export const updateItemInCart = (cartItem: CartItemType) => async (
  dispatch: DispatchType
) => {
  dispatch({
    type: UPDATE_ITEM_IN_CART,
    payload: cartItem,
  });
};

export const getCart = () => async (dispatch: DispatchType) => {
  const cart = JSON.parse(localStorage.getItem("BoardSourceCart"));
  dispatch({
    type: ADD_MULTIPLE_ITEMS_TO_CART,
    payload: cart,
  });
};

// export const clearCart = () => async (dispatch, getState) => {
//   dispatch({
//     type: UPDATE_ITEM_IN_CART
//   });
// };

export const addMultipleItemToCart = (
  cartItems: CartItemType[],
  mainProductId: string
) => async (dispatch: DispatchType) => {
  const kitId = uuidv1();
  dispatch({
    type: ADD_MULTIPLE_ITEMS_TO_CART,
    payload: cartItems.map((c) => ({ ...c, kitId, mainProductId })),
  });
  toast.success("Items Added To Your Cart!", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const clearCart = () => (dispatch: DispatchType) => {
  dispatch({ type: CLEAR_CART });
};

export default (state = cartInitialState, action: ActionType) => {
  switch (action.type) {
    case ADD_ITEM_TO_CART:
      const itemAlreadyInCart = state.cart.find(
        (cartItem) => cartItem.id === action.payload.id
      );
      let cart;
      if (itemAlreadyInCart) {
        cart = state.cart.map((cartItem) =>
          cartItem.id === itemAlreadyInCart.id
            ? { ...cartItem, qty: cartItem.qty + action.payload.qty }
            : cartItem
        );
      } else {
        cart = [...state.cart, action.payload];
      }

      return {
        ...state,
        cart,
      };

    case ADD_MULTIPLE_ITEMS_TO_CART:
      return {
        ...state,
        cart: [...state.cart, ...action.payload],
      };

    case REMOVE_ITEM_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter((c) => c.id !== action.payload),
      };
    case UPDATE_ITEM_IN_CART:
      const isKitItem = action.payload.hasOwnProperty("kitId");

      return {
        ...state,
        cart: state.cart.map((c) => {
          if (isKitItem) {
            return c.kitId &&
              c.kitId === action.payload.kitId &&
              c.id === action.payload.id
              ? action.payload
              : c;
          } else {
            return c.id === action.payload.id ? action.payload : c;
          }
        }),
      };
    case CLEAR_CART:
      return {
        ...state,
        cart: [],
      };
    case CART_INSTOCK_CHECK_PASS:
      return {
        ...state,
        cart: action.payload.cart,
        allInStock: action.payload.allInStock,
        itemsNotInStock: action.payload.failingProducts,
      };
    case CART_INSTOCK_CHECK_FAIL:
      return {
        ...state,
        cart: action.payload.cart,
        allInStock: action.payload.allInStock,
        itemsNotInStock: action.payload.failingProducts,
      };
    case GET_TOTAL_PASS:
      return {
        ...state,
        pickedOptions: action.payload.kitOptions,
      };
    default:
      return state;
  }
};
