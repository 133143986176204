import React from "react";
import { connect } from "react-redux";
import Button from "../../../utilities/buttons/button.js";
import cartIcon from "../../../../images/icons/cart.svg";
import cartIconHover from "../../../../images/icons/cart_hover.svg";
import "./cartIcon.sass";
import CountUp from "react-countup";

const CartIcon = ({ cartCount }) => {
  const count =
    cartCount > 0 ? (
      <div className="cart-icon__count">
        <CountUp
          end={cartCount}
          preserveValue={true}
          className="cart-icon__count__counter"
        />
      </div>
    ) : (
      ""
    );
  return (
    <div className="cart-icon">
      {count}
      <Button
        style="icon"
        type="link"
        path="/cart"
        imageSrc={cartIcon}
        imageSrcHover={cartIconHover}
        imageAlt="shopping cart"
        scale=".96"
        ariaText="open cart modal"
      ></Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartCount: state.cart.cart.reduce((max, cartItem) => {
    return max + cartItem.qty;
  }, 0),
});
export default connect(mapStateToProps, {})(CartIcon);
