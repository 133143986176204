import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";
import ModalWrapper from "./modalAuto/modalWrapper.js";

// import { Transition } from "react-transition-group";
import modalClose from "../../../images/icons/modal_close.svg";
import ReactModal from "react-modal";

import "./modal.sass";
import { lockScroll } from "../../../helpers";
type ModalProps = {
  modalType: "auto" | "full";
  open?: boolean;
  toggleModal: (e: any) => void;
  modalColor?: string;
  ModalContent: any;
  modalProp?: any;
  login?: boolean;
};
const Modal = (props: ModalProps) => {
  const [modalTop, setModalTop] = useState(0);
  const checkKey = (e) => {
    if (props.open) {
      if (e.keyCode === 27) {
        if (typeof props.toggleModal === "function") {
          props.toggleModal(null);
        }
      }
    }
  };

  const styleModalPosition = () => {
    const scrollPosition = window.pageYOffset;
    // console.log(scrollPosition);
    setModalTop(scrollPosition);
  };
  const { ModalContent } = props;
  const duration = 300;
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  useEffect(() => {
    lockScroll(props.open);
    styleModalPosition();
    window.addEventListener("keydown", checkKey);
    window.addEventListener("resize", () => styleModalPosition());
    return () => {
      window.removeEventListener("keydown", checkKey);
    };
  });

  return (
    <Transition
      in={props.open}
      timeout={duration}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <div className={`modal`} style={props.login ? {} : { top: modalTop }}>
            <div
              className={`modal__overlay ${
                props.modalColor ? props.modalColor : ""
              }`}
              //@ts-ignore
              tabIndex="0"
              onClick={(e) => {
                e.stopPropagation();
                props.toggleModal(e);
              }}
            >
              <ModalWrapper
                toggleModal={props.toggleModal}
                modalClose={modalClose}
                modalType={props.modalType}
                top={modalTop}
              >
                <ModalContent
                  modalProp={props.modalProp ? props.modalProp : null}
                  close={props.toggleModal}
                />
              </ModalWrapper>

              {/* <ReactModal isOpen={this.props.open}>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ut, id
            excepturi soluta sequi inventore reprehenderit rem minus, fugiat
            eaque unde officia veniam perspiciatis sapiente in iste suscipit
            sunt veritatis distinctio.
          </p>
        </ReactModal> */}
              {/* {this.returnModal(this.props.modalType, this.props)} */}
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default Modal;
