import axios from "axios";
import { DispatchType, GetStateType, ActionType } from ".";
import { IArticle } from "../../server/models/articles";
import article from "../components/pages/article";
import {
  GET_ARTICLE,
  GET_ARTICLES,
  ADD_ARTICLE,
  DELETE_ARTICLE,
  EDIT_ARTICLE,
  ARTICLES_LOADING,
} from "./types";
import { userToken } from "./user";
export const articleInitialState: ArticleStateType = {
  articles: [],
  article: null,
  loading: false,
};
export type ArticleStateType = {
  articles: IArticle[];
  article: IArticle;
  loading: boolean;
};
export const setArticlesLoading = () => ({ type: ARTICLES_LOADING });
export const getArticles = (all: boolean) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const state = getState();
  console.log("I am told to fetch");
  if (state.article.articles.length === 0) {
    console.log("I need to fetch ");
    dispatch(setArticlesLoading());
    axios
      .get(all ? "/api/articles/all" : "/api/articles")
      .then((res) => {
        dispatch({
          type: GET_ARTICLES,
          payload:
            res.data.message && res.data.message === "no Articles Yet"
              ? []
              : res.data,
        });
      })
      .catch((error) => {
        console.log("I got an error in get articles", error);
      });
  }
};

export const getArticle = (id: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const state = getState();
  if (!state.article.loading) {
    if (state.article.articles.length !== 0) {
      const newArticle = state.article.articles.find(
        (article) => article._id === id
      );
      if (newArticle) {
        dispatch({
          type: GET_ARTICLE,
          payload: newArticle,
        });
      } else {
        dispatch(setArticlesLoading());
        axios
          .get(`/api/articles/${id}`)
          .then((res) => {
            dispatch({
              type: GET_ARTICLE,
              payload: res.data,
            });
          })
          .catch((error) => {
            console.log("I got an error in get article", error);
          });
      }
    }
  }
};
export const deleteArticle = (id: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  dispatch(setArticlesLoading());
  axios
    .delete(`/api/articles/${id}`)
    .then((res) => {
      dispatch({
        type: DELETE_ARTICLE,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log("I got an error in get article", error);
    });
};

export const createArticle = (
  article: IArticle,
  cb: (res: any, error: any) => void
) => async (dispatch: DispatchType, getState: GetStateType) => {
  dispatch(setArticlesLoading());
  axios
    .post(`/api/articles`, article, userToken(getState))
    .then((res) => {
      if (cb && typeof cb === "function") {
        cb(res, null);
      }
      dispatch({
        type: ADD_ARTICLE,
        payload: res.data.Article,
      });
    })
    .catch((error) => {
      if (cb && typeof cb === "function") {
        cb(null, error);
      }
      console.log("I got an error in get article", error);
    });
};

export const editArticle = (
  article: IArticle,
  cb: (res: any, error: any) => void
) => async (dispatch: DispatchType, getState: GetStateType) => {
  dispatch(setArticlesLoading());
  axios
    .patch(`/api/articles/${article._id}`, article, userToken(getState))
    .then((res) => {
      if (cb && typeof cb === "function") {
        cb(res, null);
      }
      dispatch({
        type: EDIT_ARTICLE,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (cb && typeof cb === "function") {
        cb(null, error);
      }
      console.log("I got an error in get article", error);
    });
};

export default (state = articleInitialState, action: ActionType) => {
  switch (action.type) {
    case ARTICLES_LOADING:
      return { ...state, loading: true };
    case GET_ARTICLES:
      return {
        ...state,
        articles: action.payload,
        loading: false,
      };
    case GET_ARTICLE:
      return {
        ...state,
        article: action.payload,
        loading: false,
      };
    case ADD_ARTICLE:
      return {
        ...state,
        article: action.payload,
        loading: false,
      };
    case EDIT_ARTICLE:
      return {
        ...state,
        article: action.payload,
        loading: false,
      };
    case DELETE_ARTICLE:
      return {
        ...state,
        article: {},
        loading: false,
      };

    default:
      return state;
  }
};
