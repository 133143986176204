import axios from "axios";
import {
  GET_PROJECTS,
  PROJECTS_LOADING,
  ADD_PROJECT,
  EDIT_PROJECT,
  GET_PROJECT,
  DELETE_PROJECT,
  GOT_ERR,
  PROJECTS_FORM_LOADING,
  UPDATING_PROJECT,
} from "./types";
import { toast } from "react-toastify";
import { userToken, updateUser, getUserFull } from "./user";
import { overViewArray, needToLoginMessage, isTypeOfProject } from "../helpers";
import { DispatchType, GetStateType, ActionType } from ".";
import { IProject } from "../../server/models/projects";
export type ProjectStateType = {
  projects: IProject[];
  project: IProject;
  loading: boolean;
  formLoading: boolean;
  msg: string;
  status: any;
  success: any;
  err: any;
};
export const projectInitialState: ProjectStateType = {
  projects: [],
  project: null,
  loading: false,
  formLoading: false,
  msg: "",
  status: null,
  success: null,
  err: {},
};

export const getProjects = () => async (dispatch: DispatchType) => {
  dispatch(setProjectsLoading(PROJECTS_LOADING));
  axios
    .get("/api/projects")
    .then((res) => {
      console.log("data", res.data);
      dispatch({
        type: GET_PROJECTS,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error);
      if (error && error.response && error.response.data) {
        dispatch(
          gotErr(null, error.response.status, null, error.response.data.error)
        );
      }
    });
};
export const getAllProjects = () => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  dispatch(setProjectsLoading(PROJECTS_LOADING));
  axios
    .get("/api/projects/all", userToken(getState))
    .then((res) => {
      dispatch({
        type: GET_PROJECTS,
        payload: res.data.projects,
      });
    })
    .catch((error) => {
      if (error && error.response && error.response.data) {
        dispatch(
          gotErr(null, error.response.status, null, error.response.data.error)
        );
      }
    });
};
export const createProject = (
  project: IProject,
  cb: (res: any, err: any) => void
) => (dispatch: DispatchType, getState: GetStateType) => {
  dispatch(setProjectsLoading(PROJECTS_LOADING));
  axios
    .post("/api/projects", project, userToken(getState))
    .then((res) => {
      dispatch({
        type: ADD_PROJECT,
        payload: res.data,
      });
      if (cb) {
        cb(res, null);
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data) {
        dispatch(
          gotErr(null, error.response.status, null, error.response.data.error)
        );
      }
      if (cb) {
        cb(null, error);
      }
    });
};

export const deleteProject = (id: string) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  console.log(`deleting project:${id}`);
  dispatch(setProjectsLoading(PROJECTS_LOADING));
  axios
    .delete(`/api/projects/${id}`, userToken(getState))
    .then((res) =>
      dispatch({
        type: DELETE_PROJECT,
        payload: id,
      })
    )
    .catch((error) => {
      if (error && error.response && error.response.data) {
        dispatch(
          gotErr(null, error.response.status, null, error.response.data.error)
        );
      }
    });
};

export const getProject = (id: string) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  dispatch(setProjectsLoading(PROJECTS_LOADING));
  const state = getState().project;
  const project =
    state.hasOwnProperty("projects") && state.projects.length > 0
      ? state.projects.find((p) => p._id === id)
      : false;
  if (
    isTypeOfProject(project) &&
    project.hasOwnProperty("full") &&
    project.full
  ) {
    dispatch({
      type: GET_PROJECT,
      payload: project,
    });
  } else {
    axios
      .get(`/api/projects/${id}`)
      .then((res) => {
        const project = {
          ...res.data,
          overView: overViewArray(res.data, true),
        };
        dispatch({
          type: GET_PROJECT,
          payload: project,
        });
      })
      .catch((error) => {
        console.log("error in catch", error);
        if (error && error.response && error.response.data) {
          dispatch(
            gotErr(
              error.response.message,
              error.response.status,
              null,
              error.response.data.error
            )
          );
        }
      });
  }
};

export const editProject = (
  project: IProject,
  cb: (res: any, err: any) => void
) => (dispatch: DispatchType, getState: GetStateType) => {
  dispatch(setProjectsLoading(PROJECTS_LOADING));
  axios
    .patch(`/api/projects/${project._id}`, project, userToken(getState))
    .then((res) => {
      dispatch({ type: EDIT_PROJECT, payload: project });
      if (cb) {
        cb(res, null);
      }
    })
    .catch((error) => {
      if (error && error.response && error.response.data) {
        dispatch(
          gotErr(null, error.response.status, null, error.response.data.error)
        );
      }
      if (cb) {
        cb(null, error);
      }
    });
};

export const followProject = (
  projectId: string,
  submit: any,
  cb: () => void
) => (dispatch: DispatchType, getState: GetStateType) => {
  dispatch(setProjectsLoading(PROJECTS_FORM_LOADING));
  axios
    .post(
      `/api/projects/follow/${projectId}`,
      { submittedForm: submit },
      userToken(getState)
    )
    .then((res) => {
      //@ts-ignore
      updateUser(res.user);
      if (cb) {
        cb();
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error(needToLoginMessage, {
          position: "top-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      if (error && error.response && error.response.data) {
        dispatch(
          gotErr(
            error.response.data.message,
            error.response.status,
            error.response.data.success,
            error.response.data.error
          )
        );
      }
    });
};

export const manageFollowProject = (projectId: string, email: string) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  console.log("this is the email I am updating to ", email);

  dispatch(setProjectsLoading(PROJECTS_FORM_LOADING));
  axios
    .patch(
      `/api/projects/unfollow/${projectId}`,
      { email },
      userToken(getState)
    )
    .then((res) => {
      getUserFull()(dispatch, getState);
      toast.success("Updated your email preferences", {
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    })
    .catch((error) => {
      if (error && error.response && error.response.data) {
        dispatch(
          gotErr(
            error.response.data.message,
            error.response.status,
            error.response.data.success,
            error.response.data.error
          )
        );
      }
    });
};
export const updateProject = (id: string, update: any) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  dispatch(setProjectsLoading(PROJECTS_LOADING));
  axios
    .post(`/api/projects/update/${id}`, update, userToken(getState))
    .then((res) =>
      dispatch({
        type: UPDATING_PROJECT,
        payload: res.data.message,
      })
    )
    .catch((error) => {
      if (error && error.response && error.response.data) {
        dispatch(
          gotErr(null, error.response.status, null, error.response.data.error)
        );
      }
    });
};

export const gotErr = (msg: string, status: any, success: any, err: any) => {
  return {
    type: GOT_ERR,
    payload: { msg, status, success, err },
  };
};

export const setProjectsLoading = (TYPE: string) => ({ type: TYPE });

export default (state = projectInitialState, action: ActionType) => {
  switch (action.type) {
    case GET_PROJECTS: {
      return { ...state, projects: action.payload, loading: false };
    }
    case GET_PROJECT: {
      return {
        ...state,
        projects: state.projects.map((project) =>
          project._id === action.payload._id
            ? { ...action.payload, full: true }
            : project
        ),
        project: action.payload,
        loading: false,
      };
    }
    case PROJECTS_LOADING:
      return { ...state, loading: true };
    case DELETE_PROJECT:
      return {
        ...state,
        project: {},
      };
    case EDIT_PROJECT: {
      return {
        ...state,
        project: action.payload,
        loading: false,
      };
    }
    case ADD_PROJECT: {
      return {
        ...state,
        project: action.payload,
        loading: false,
      };
    }
    case UPDATING_PROJECT: {
      return {
        ...state,
        msg: action.payload,
      };
    }
    case GOT_ERR:
      return {
        ...state,
        msg: action.payload.msg,
        status: action.payload.status,
        success: action.payload.success,
        err: action.payload.err,
      };
    default:
      return state;
  }
};
