import axios from "axios";
import { ADD_NOTIFICATION, GET_NOTIFICATIONS } from "./types";

import { userToken } from "./user";
import { INotifications } from "../../server/models/notifications";
import { DispatchType, GetStateType, ActionType } from ".";
export type NotificationStateType = INotifications[];
export const notificationInitialState: NotificationStateType = [];

export const postANotification = (notification: any) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  axios
    .post("/api/notification", notification, userToken(getState))
    .then((res) => {
      dispatch({
        type: ADD_NOTIFICATION,
        payload: res.data.notification,
      });
    })
    .catch((error) => {
      console.log("error in posting a new notification", error);
    });
};

export const getNotifications = () => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  axios
    .get("/api/notification", userToken(getState))
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log("I have a problem getting the notifications", error);
    });
};

export const addNotificationToItems = (
  productIds: string[],
  projectIds: string[],
  id: string
) => async (_dispatch: DispatchType, getState: GetStateType) => {
  const items = { productIds, projectIds };
  axios
    //@ts-ignore
    .get(`/api/notification/addtoitems/${id}`, items, userToken(getState))
    .then((res) => {
      console.log("back from adding items", res);
    })
    .catch((error) => {
      console.log("I have a problem adding to items", error);
    });
};
export const removeNotificationFromItems = (
  productIds: string[],
  projectIds: string[],
  id: string
) => async (_dispatch: DispatchType, getState: GetStateType) => {
  const items = { productIds, projectIds };
  axios
    //@ts-ignore
    .get(`/api/notification/removefromitems/${id}`, items, userToken(getState))
    .then((res) => {
      console.log("back from adding items", res);
    })
    .catch((error) => {
      console.log("I have a problem adding to items", error);
    });
};

export default (state = notificationInitialState, action: ActionType) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return [...state, action.payload];
    case GET_NOTIFICATIONS:
      return [...action.payload];
    default:
      return state;
  }
};
