import { combineReducers } from "redux";
import userReducer, { UserStateType, userInitialState } from "./user";
import imageReducer, { ImageStateType, imageInitialState } from "./imageUpload";
import projectReducer, {
  projectInitialState,
  ProjectStateType,
} from "./projects";
import productReducer, {
  productInitialState,
  ProductStateType,
} from "./products";
import checkoutReducer, {
  CheckoutStateType,
  checkoutInitialState,
} from "./checkout";
import shippingReducer, {
  ShippingStateType,
  shippingInitialState,
} from "./shipping";
import cartReducer, { CartStateType, cartInitialState } from "./cart";
import configReducer, {
  siteConfigInitialState,
  SiteConfigType,
} from "./siteConfig";
import ordersReducer, { OrdersStateType, orderInitialState } from "./orders";
import couponReducer, { CouponStateType, couponInitialState } from "./coupon";
import articleReducer, {
  articleInitialState,
  ArticleStateType,
} from "./articles";
//import { connectRouter } from "connected-react-router";
import notificationsReducer, {
  notificationInitialState,
  NotificationStateType,
} from "./notifications";
export const rootReducer = (history) =>
  combineReducers({
    user: userReducer,
    project: projectReducer,
    image: imageReducer,
    product: productReducer,
    checkout: checkoutReducer,
    shipping: shippingReducer,
    config: configReducer,
    cart: cartReducer,
    order: ordersReducer,
    coupon: couponReducer,
    notifications: notificationsReducer,
    article: articleReducer,
  });

export type StateType = {
  user: UserStateType;
  article: ArticleStateType;
  product: ProductStateType;
  config: SiteConfigType;
  project: ProjectStateType;
  cart: CartStateType;
  checkout: CheckoutStateType;
  shipping: ShippingStateType;
  image: ImageStateType;
  order: OrdersStateType;
  coupon: CouponStateType;
  notifications: NotificationStateType;
};
export const blankState: StateType = {
  user: userInitialState,
  article: articleInitialState,
  product: productInitialState,
  config: siteConfigInitialState,
  project: projectInitialState,
  cart: cartInitialState,
  checkout: checkoutInitialState,
  shipping: shippingInitialState,
  image: imageInitialState,
  order: orderInitialState,
  coupon: couponInitialState,
  notifications: notificationInitialState,
};
export type GetStateType = () => StateType;
export type ActionType = {
  type: string;
  payload?: any;
};
export type DispatchType = (action: ActionType) => void;
