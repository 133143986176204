import { rootReducer } from "./reducers/index";

import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { canUseDOM } from "./helpers.ts";

const configureStore = (preloadedState) => {
  if (canUseDOM) {
    let token = "";
    try {
      token = localStorage.getItem("token");
    } catch {
      console.log("failed to get token from ls");
    }
    preloadedState.user.token = token;
  }
  const store = createStore(
    rootReducer(),
    preloadedState,
    composeWithDevTools(applyMiddleware(thunk))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducers", () => {
      const nextRootReducer = require("./reducers").default;
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
};

export default configureStore;
