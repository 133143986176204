export const USER_LOADING = "USER_LOADING";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const FAILED_GETTING_USER = "FAILED_GETTING_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const FULL_USER = "FULL_USER";
export const USER_CREATED = "USER_CREATED";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const END_UPLOAD = "END_UPLOAD";
export const START_UPLOAD = "START_UPLOAD";

export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const UPDATING_PROJECT = "UPDATING_PROJECT";
export const PROJECTS_LOADING = "PROJECTS_LOADING";
export const PROJECTS_FORM_LOADING = "PROJECTS_FORM_LOADING";
export const UPDATE_USER = "UPDATE_USER";
export const GOT_ERR = "GOT_ERR";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const PRODUCTS_LOADING = "PRODUCTS_LOADING";
export const CHECKOUT_LOADING = "CHECKOUT_LOADING";
export const CHECKOUT_ERROR = "CHECKOUT_ERROR";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_INTENT = "CHECKOUT_INTENT";
export const CHECKOUT_COUPON_CHECK = "CHECKOUT_COUPON_CHECK";
export const USER_CHECKOUT = "USER_CHECKOUT";
export const ADDRESS_SUCCESS = "ADDRESS_SUCCESS";
export const CART_NOT_SHIPPABLE = "CART_NOT_SHIPPABLE";
export const SHIPPING_LOADING = "SHIPPING_LOADING";
export const PICK_SHIPPING = "PICK_SHIPPING";
export const OVER_RIDE_SHIPPING = "OVER_RIDE_SHIPPING";
export const ADDRESS_ERROR = "ADDRESS_ERROR";
export const RATES_ERROR = "RATES_ERROR";
export const GOT_RATES = "GOT_RATES";
export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const ADD_MULTIPLE_ITEMS_TO_CART = "ADD_MULTIPLE_ITEMS_TO_CART";
export const UPDATE_ITEM_IN_CART = "UPDATE_ITEM_IN_CART";
export const UPDATE_CONFIG = "UPDATE_CONFIG";
export const GET_CONFIG = "GET_CONFIG";
export const CONFIG_LOADING = "CONFIG_LOADING";
export const CONFIG_ERROR = "CONFIG_ERROR";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_ORDER = " GET_ORDER";
export const GET_ORDERS = "GET_ORDERS";
export const ORDERS_LOADING = "ORDERS_LOADING";
export const ORDER_SHIPPED = "ORDER_SHIPPED";
export const GET_COUPONS = "GET_COUPONS";
export const ADD_COUPON = "ADD_COUPON";
export const EDIT_COUPON = "EDIT_COUPON";
export const GET_COUPON = "GET_COUPON";
export const CLEAR_CART = "CLEAR_CART";
export const EDIT_ARTICLE = "EDIT_ARTICLE";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const GET_ARTICLE = "GET_ARTICLE";
export const GET_ARTICLES = "GET_ARTICLES";
export const ARTICLES_LOADING = "ARTICLES_LOADING";
export const PAYPAL_CHECKOUT = "PAYPAL_CHECKOUT";
export const CART_INSTOCK_CHECK_PASS = "CART_INSTOCK_CHECK_PASS";
export const CART_INSTOCK_CHECK_FAIL = "CART_INSTOCK_CHECK_FAIL";

export const GET_TOTAL_PASS = "GET_TOTAL_PASS";
export const GET_TOTAL_FAIL = "GET_TOTAL_FAIL";
