import React, { Component } from "react";
import { connect } from "react-redux";
import "./createNewAccount.sass";
import ShortText from "../../utilities/inputs/shortText/shortText";
import { login, register } from "../../../reducers/user";
import Button from "../../utilities/buttons/button";
import logo from "../../../images/brand/boardsource_logo.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toggle from "../../utilities/inputs/toggle/toggle";


class CreateNewAccount extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", name: "", username: "", password: "", news: true };
  }
  checkLogin = () => {
    // toast.error('🦄 Wow so easy!', {
    //   position: "top-center"
    //   autoClose: 5000
    //   hideProgressBar: false
    //   closeOnClick: true
    //   pauseOnHover: true
    //   draggable: true
    //   });
    const { email, password, name, username } = this.state;
    return (
      email &&
      email !== " " &&
      password &&
      password !== " " &&
      name &&
      name !== " " &&
      username &&
      username !== " "
    );
  };
  componentWillReceiveProps(nextprops) {
    if (nextprops.isAuthenticated) {
      this.props.close();
    }
  }
  changeHandler = (name, newInfo) => {
    this.setState({ [name]: newInfo });
  };
  login = () => {
    if (this.checkLogin()) {
      this.props.register({
        email: this.state.email,
        password: this.state.password,
        name: this.state.name,
        userName: this.state.username,
        news: this.state.news
      });
    }
  };
  renderCryingMessage = () => {
    if (!this.state.news) {
      return (
        <p className="beg">
          cmon who knows what your missing.....
        </p>
      )
    }
  }

  renderLoginButton = () => {
    if (this.checkLogin()) {
      return (
        <Button
          style="outline"
          color="green"
          text="Create New Account"
          function={this.login}
          filled={false}
          type="function"
        />
      );
    } else {
      return (
        <Button
          style="outline"
          color="red"
          text="...Create Account"
          function={() =>
            console.log("you should see if you have filled out both fields")
          }
          filled={false}
          type="function"
        />
      );
    }
  };
  render() {
    return (
      <div className="create">
        <div className="create__main">
          <img className="logo" src={logo} alt="Boardsource Logo" />
          <div className="create__main__inputs">
            <div className="create__main__inputs__input">
              <ShortText
                required={true}
                type="text"
                name="name"
                label="name"
                placeholder="Full Name"
                labelHidden={true}
                fillField={this.changeHandler}
                field={"name"}
              />
            </div>
            <div className="create__main__inputs__input">
              <ShortText
                required={true}
                type="text"
                name="username"
                label="username"
                placeholder="Username"
                labelHidden={true}
                fillField={this.changeHandler}
                field={"username"}
              />
            </div>
            <div className="create__main__inputs__input">
              <ShortText
                required={true}
                type="email"
                name="email"
                label="email"
                placeholder="E-Mail"
                labelHidden={true}
                fillField={this.changeHandler}
                field={"email"}
              />
            </div>
            <div className="create__main__inputs__input">
              <ShortText
                required={true}
                type="password"
                name="password"
                label="password"
                placeholder="Password"
                labelHidden={true}
                fillField={this.changeHandler}
                field="password"
              />
            </div>
            <div className="create__main__inputs__input sub">
              <Toggle
                label={"subscribe to newsletter and updates"}
                value={this.state.news}
                onChange={(e) =>
                  this.changeHandler("news", e.target.checked)
                }
              />
              {this.renderCryingMessage()}
            </div>
            <div className="create__main__inputs__button">
              {this.renderLoginButton()}
              <button
                className="create__main__inputs__login"
                onClick={this.props.switchModal}
              >
                I Already Have An Account
              </button>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  error: state.error,
});

export default connect(mapStateToProps, { login, register })(CreateNewAccount);
