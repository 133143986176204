import React, { Component } from "react";
import ButtonContent from "../buttonContent/buttonContent.js";

import "../button.sass";

class ButtonFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <ButtonContent {...this.props} toggleModal={this.toggleModal} />;
  }
}

export default ButtonFunction;
