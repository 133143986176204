import React, { Component } from "react";
import logo from "../../../images/brand/boardsource_logo.svg";

import "./footer.sass";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="footer wrapper">
        <div className="footer__content">
          <div className="brand">
            <img src={logo} alt="boardsource logo" />
          </div>
          <p>
            Copyright &copy;{new Date().getFullYear()}, Boardsource. All Rights
            Reserved.
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
