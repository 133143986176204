import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { slide as Menu } from "react-burger-menu";
import { Link, NavLink } from "react-router-dom";
import Desktop from "./desktop/desktop";
import Mobile from "./mobile/mobile.js";
// import { OffCanvas, OffCanvasBody, OffCanvasMenu } from "react-offcanvas";
// import Sidebar from "react-sidebar";
// import CartPreview from "../../cartPreview/cartPreview";
import Button from "../../utilities/buttons/button.js";
// import { getUser } from "../../../reducers/user.js";
import AuthModal from "../../auth/authModal";
import Modal from "../../utilities/modal/modal";
import { Transition } from "react-transition-group";
// import { createBrowserHistory } from "history";
import discordIcon from "../../../images/icons/discord-grey.svg";
import discordIconHover from "../../../images/icons/discord-color.svg";
import instagramIcon from "../../../images/icons/instagram-grey.svg";
import instagramIconHover from "../../../images/icons/instagram-color.svg";

import "./navigation.sass";

import closeButton from "../../../images/icons/modal_close.svg";

import logo from "../../../images/brand/boardsource_logo.svg";
//import { history } from "../../../store";
import CartIcon from "./cartIcon/cartIcon";
import { IUser } from "../../../../server/models/user";
import { StateType } from "../../../reducers";
import { canUseDOM } from "../../../helpers";
type NavProps = {
  user: IUser;
  isAuthenticated: boolean;
  didTry: boolean;
};

const Nav = (props: NavProps) => {
  const navItems = [
    {
      link: "/projects",
      text: "Projects",
    },
    {
      link: "/store",
      text: "Store",
    },
    {
      link: "/help",
      text: "Help",
    },
  ];
  const [isOpen, setIsOpen] = useState(false),
    [open, setOpen] = useState(false);

  const isMenuOpen = (state: any) => {
    setIsOpen(state.isOpen);
  };
  const auth = () => {
    if (props.user && Object.keys(props.user).length === 0) {
      return false;
    }
    if (props.isAuthenticated) {
      return true;
    } else if (canUseDOM && localStorage.getItem("token") && !props.didTry) {
      return true;
    } else {
      return false;
    }
  };
  const toggleModal = () => {
    setOpen(!open);
  };

  const renderAccountButton = () => {
    if (auth()) {
      return (
        <span className="account-button" onClick={() => setIsOpen(false)}>
          <Button
            style="filled"
            color="purple"
            type="link"
            path="/my-account"
            text="My Account"
          />
        </span>
      );
    } else {
      return (
        <span className="account-button">
          <Button
            style="filled"
            color="purple"
            type="function"
            function={toggleModal}
            // modalType="full"
            // ModalContent={AuthModal}
            text={"Login"}
          />
        </span>
      );
    }
  };
  const handleClick = () => {
    setIsOpen(false);
  };
  const duration = 300;
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };
  //also styled in navigation.sass
  var hamburgerStyles = {
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      left: "36px",
      top: "36px",
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "40px",
      width: "40px",
      marginRight: "2em",
      marginTop: "2em",
    },
    bmCross: {
      background: "white",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "white",
      // padding: "2.5em 1.5em 0",
      // fontSize: "1.15em"
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      // color: "#b8b7ad",
      // padding: "0.8em"
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      // background: "red",
      background: "rgba(255, 255, 255, 0.3)",
      backdropFilter: "blur(5px)",
      paddingRight: "20px",
      marginRight: "20px",
    },
  };

  return (
    <div>
      <Menu
        right
        styles={hamburgerStyles}
        isOpen={isOpen}
        onStateChange={isMenuOpen}
        customBurgerIcon={false}
        customCrossIcon={<img src={closeButton} alt="close button" />}
        crossButtonClassName={"hamburger-close"}
        crossClassName={"hamburger"}
      >
        <div className="hamburger__nav">
          {navItems.map((navItem, index) => (
            <div className="hamburger__nav__item" key={index}>
              <NavLink
                onClick={() => handleClick()}
                exact
                // onClick={this.handleNavItemClick(n.link)}
                to={navItem.link}
                activeClassName="account__navigation__nav__selected"
              >
                {navItem.text}
              </NavLink>
            </div>
          ))}
          <div className="hamburger__nav__icons">
            <div className="hamburger__nav__icons__cart">
              <CartIcon
                //@ts-ignore
                onClick={() => setIsOpen(false)}
              />
            </div>
            <span className="social-group">
              <div className="hamburger__nav__icons__discord">
                <Button
                  style="icon"
                  type="link"
                  src="https://discord.gg/ycYUguR"
                  imageSrc={discordIcon}
                  imageSrcHover={discordIconHover}
                  imageAlt="discord logo"
                  scale=".96"
                  ariaText="enter Boardsource Discord"
                ></Button>
              </div>
              <div className="hamburger__nav__icons__instagram">
                <Button
                  style="icon"
                  type="link"
                  src="https://www.instagram.com/boardsource.xyz/"
                  imageSrc={instagramIcon}
                  imageSrcHover={instagramIconHover}
                  imageAlt="instagram logo"
                  scale=".96"
                  ariaText="View Boardsource's Instagram"
                ></Button>
              </div>
            </span>
          </div>

          {renderAccountButton()}
        </div>
      </Menu>
      <Transition
        in={open}
        timeout={duration}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {(state) => {
          return (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <Modal
                modalType={"auto"}
                ModalContent={AuthModal}
                open={open}
                toggleModal={toggleModal}
              />
            </div>
          );
        }}
      </Transition>
      {/* <Sidebar
          open={this.state.offMenu}
          sidebar={<CartPreview />}
          pullRight={true}
          shadow={false}
          onSetOpen={this.offMenu}
          overlayClassName="cart__preview__sidebar__overlay"
        /> */}
      <nav className="nav wrapper">
        <div className="nav__content content">
          <div className="nav__content__logo">
            <Link to="/">
              <img className="nav-logo" src={logo} alt="Boardsource Logo" />
            </Link>
          </div>
          <Desktop items={navItems} />
          {/* this was the one sort of working */}
          {/* <button onClick={this.offMenu}>open</button> */}
          <Mobile openMenu={() => setIsOpen(true)} />
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = (state: StateType) => ({
  user: state.user.currentUser,
  isAuthenticated: state.user.isAuthenticated,
  didTry: state.user.didTry,
});

export default connect(mapStateToProps, {})(Nav);
