import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Button from "../../../utilities/buttons/button.js";
import AuthModal from "../../../auth/authModal";
import CartIcon from "../cartIcon/cartIcon";
import discordIcon from "../../../../images/icons/discord-grey.svg";
import discordIconHover from "../../../../images/icons/discord-color.svg";
import instagramIcon from "../../../../images/icons/instagram-grey.svg";
import instagramIconHover from "../../../../images/icons/instagram-color.svg";
import { StateType } from "../../../../reducers/index.js";
import { IUser } from "../../../../../server/models/user";
import { canUseDOM } from "../../../../helpers";
type ItemsType = { link: string; text: string };
type DesktopProps = {
  items: ItemsType[];
  user: IUser;
  isAuthenticated: boolean;
  didTry: boolean;
};
const Desktop = (props: DesktopProps) => {
  const auth = () => {
    // this.props.getUser();

    if (props.user && Object.keys(props.user).length === 0) {
      return false;
    }
    if (props.isAuthenticated) {
      return true;
    } else if (canUseDOM && localStorage.getItem("token") && !props.didTry) {
      return true;
    } else {
      return false;
    }
  };
  const renderAccountButton = () => {
    if (auth()) {
      return (
        <Button
          style="filled"
          color="purple"
          type="link"
          path="/my-account"
          text="My Account"
        />
      );
    } else {
      return (
        <Button
          color="purple"
          type="modal"
          modalType="auto"
          ModalContent={AuthModal}
          text="Login"
          filled={true}
          login
        />
      );
    }
  };
  return (
    <div className="nav__content__desktop">
      <div className="nav__content__desktop__nav">
        {props.items.map((i, index) => (
          <NavLink
            exact
            to={i.link}
            className={`nav__content__desktop__nav__item`}
            key={index}
            activeClassName="active"
          >
            {i.text}
          </NavLink>
        ))}
        <div className="nav__content__desktop__nav__button discord">
          <Button
            style="icon"
            type="link"
            src="https://discord.gg/ycYUguR"
            imageSrc={discordIcon}
            imageSrcHover={discordIconHover}
            imageAlt="discord logo"
            scale=".96"
            ariaText="enter Boardsource Discord"
          ></Button>
        </div>
        <div className="nav__content__desktop__nav__button">
          <Button
            style="icon"
            type="link"
            src="https://www.instagram.com/boardsource.xyz/"
            imageSrc={instagramIcon}
            imageSrcHover={instagramIconHover}
            imageAlt="instagram logo"
            scale=".96"
            ariaText="View Boardsource's Instagram"
          ></Button>
        </div>
      </div>
      <div className="nav__content__desktop__spacer" />
      <div className="nav__content__desktop__login">
        {renderAccountButton()}
      </div>
      <div className="nav__content__desktop__nav__cart">
        <CartIcon />
      </div>
    </div>
  );
};
const mapStateToProps = (state: StateType) => ({
  user: state.user.currentUser,
  isAuthenticated: state.user.isAuthenticated,
  didTry: state.user.didTry,
});
export default connect(mapStateToProps, {})(Desktop);
