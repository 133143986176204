import axios from "axios";
import { PICK_SHIPPING, OVER_RIDE_SHIPPING, CART_NOT_SHIPPABLE } from "./types";
import { CartItemType } from "./cart";
import { DispatchType, GetStateType, ActionType } from ".";
import { userCheckOut, userToken } from "./user";
import {
  ADDRESS_SUCCESS,
  SHIPPING_LOADING,
  ADDRESS_ERROR,
  RATES_ERROR,
  GOT_RATES,
} from "./types";
export type AddressType = {
  name: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone?: string;
};
export type ShippingStateType = {
  address: AddressType;
  shipment: any;
  cart: CartItemType[];
  message: string;
  loading: boolean;
  error: any;
  verifiedAddress: AddressType;
  pickedShippingId: string;
  addressOverRide: boolean;
  email: string;
  shippingCost: number;
};
export const shippingInitialState: ShippingStateType = {
  address: null,
  shipment: {},
  cart: [],
  message: "",
  loading: false,
  error: {},
  verifiedAddress: null,
  pickedShippingId: "",
  addressOverRide: false,
  email: "",
  shippingCost: 0,
};

export const addressVerification = (
  name: string,
  street1: string,
  street2: string,
  city: string,
  state: string,
  zip: string,
  country: string,
  email: string,
  phone: string
) => async (dispatch: DispatchType) => {
  dispatch(setShippingLoading());
  const address = { name, street1, street2, city, state, zip, country,phone };
  axios
    .post("/api/shipping/addressverification", address)
    .then((res) => {
      if (res.data.message === "failed") {
        dispatch({
          type: ADDRESS_ERROR,
          payload: {
            message: res.data.message,
            error: res.data.err,
          },
        });
      } else {
        dispatch({
          type: ADDRESS_SUCCESS,
          payload: {
            verifiedAddress: res.data.address,
            address,
            email: email || "",
          },
          // payload: res.data.address
        });
      }

      userCheckOut();
    })
    .catch((error) => {
      dispatch({
        type: ADDRESS_ERROR,
        payload: {
          message: error.response.data.message + " " + error.response.data.todo,
          error: error.response.data.err,
        },
      });
    });
};

export const getRates = (address: AddressType, cart: CartItemType[]) => async (
  dispatch: DispatchType
) => {
  dispatch(setShippingLoading());
  const { name, street1, street2, city, state, zip, country, phone } = address;
  axios
    .post("/api/shipping/getrates", {
      cart,
      name,
      street1,
      street2,
      city,
      state,
      zip,
      country,
      phone,
    })
    .then((res) => {
      if (res.data.message && res.data.message === "Nothing to ship") {
        dispatch({
          type: CART_NOT_SHIPPABLE,
          payload: {
            message: res.data.message,
            cart,
          },
        });
      } else {
        dispatch({
          type: GOT_RATES,
          payload: {
            shipment: res.data.shipment,
            cart,
          },
        });
      }
      userCheckOut();
    })
    .catch((error) => {
      console.log("shipping error", error);

      dispatch({
        type: RATES_ERROR,
        payload: {
          message: error.response.data.message,
          error: error.response.data.err,
          cart,
        },
      });
    });
};

export const pickShipping = (cost: number, id: string) => async (
  dispatch: DispatchType
) => {
  dispatch({
    type: PICK_SHIPPING,
    payload: {
      cost,
      id,
    },
  });
};
export const overRIdeShipping = () => async (dispatch: DispatchType) => {
  dispatch({
    type: OVER_RIDE_SHIPPING,
  });
};
export const setShippingLoading = () => ({ type: SHIPPING_LOADING });

export default (state = shippingInitialState, action: ActionType) => {
  switch (action.type) {
    case ADDRESS_SUCCESS:
      return {
        ...state,
        verifiedAddress: action.payload.verifiedAddress,
        address: action.payload.address,
        loading: false,
        email: action.payload.email,
        message: "",
      };

    case SHIPPING_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADDRESS_ERROR:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
        error: action.payload.error,
      };
    case RATES_ERROR:
      return {
        message: action.payload.message,
        error: action.payload.error,
        cart: action.payload.cart,
        loading: false,
      };
    case CART_NOT_SHIPPABLE:
      return {
        ...state,
        loading: false,
        shippingCost: 0,
        pickedShippingId: "",
        message: action.payload.message,
        cart: action.payload.cart,
      };
    case GOT_RATES:
      return {
        ...state,
        shippingCost: 0,
        pickedShippingId: "",
        shipment: action.payload.shipment,
        cart: action.payload.cart,
        message: "",
      };
    case PICK_SHIPPING:
      return {
        ...state,
        shippingCost: action.payload.cost,
        pickedShippingId: action.payload.id,
      };
    case OVER_RIDE_SHIPPING:
      return { ...state, addressOverRide: true };
    default:
      return state;
  }
};
