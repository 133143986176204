import axios from "axios";
import { userToken } from "./user";
import {
  GET_CONFIG,
  UPDATE_CONFIG,
  CONFIG_ERROR,
  CONFIG_LOADING,
} from "./types";
import { ISiteBase, ISiteConfig } from "../../server/models/siteConfig";
import { DispatchType, GetStateType, ActionType } from ".";
export type SiteConfigType = {
  config: ISiteBase;
  error: any;
  loading: boolean;
  lastPulled?: Date;
};
export const siteConfigInitialState: SiteConfigType = {
  config: null,
  error: null,
  loading: false,
  lastPulled: null,
};

export const getConfig = (overRide: boolean) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const state = getState().config;
  if (!state.loading) {
    const now = Date.now();
    dispatch(setConfigLoading());
    axios
      .get("/api/config")
      .then((res) => {
        dispatch({
          type: GET_CONFIG,
          payload: { config: res.data, lassPulled: now },
        });
      })
      .catch((error) => {
        dispatch({
          type: CONFIG_ERROR,
          payload: error,
        });
      });
  }
};

export const setConfig = (config: ISiteBase) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  dispatch(setConfigLoading());
  axios
    .post("/api/config", config, userToken(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_CONFIG,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: CONFIG_ERROR,
        payload: error,
      });
    });
};

export const setConfigLoading = () => ({ type: CONFIG_LOADING });

export default (state = siteConfigInitialState, action: ActionType) => {
  switch (action.type) {
    case GET_CONFIG:
      return {
        ...state,
        config: action.payload.config,
        lassPulled: action.payload.lassPulled,
        loading: false,
      };

    case CONFIG_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CONFIG_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_CONFIG:
      return {
        ...state,
        config: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
