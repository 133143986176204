import React, { Component } from "react";
import PropTypes from "prop-types";

import requiredEmpty from "../../../images/icons/required-empty.svg";
import requiredFilled from "../../../images/icons/required-filled.svg";

import "./required.sass";

class Required extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  returnClass = (field) => {
    if (field === null || field === "" || field === undefined) {
      return "inactive";
    } else if (Array.isArray(field)) {
      if (field.length === 0) {
        return "inactive";
      } else {
        return "active";
      }
    } else {
      return "active";
    }
  };
  render() {
    return (
      <div className="filled">
        <div
          className={`filled__image__inactive ${this.returnClass(
            this.props.field
          )}`}
        >
          <img src={requiredEmpty} alt="grey asterisk" />
        </div>
        <div
          className={`filled__image__active ${this.returnClass(
            this.props.field
          )}`}
        >
          <img src={requiredFilled} alt="green check mark" />
        </div>
      </div>
    );
  }
}

export default Required;
