import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import { connect } from "react-redux";
import Navigation from "./components/layout/navigation/navigation";
import Footer from "./components/layout/footer/footer.js";
import ScrollToTop from "./components/utilities/scrollToTop";

import ProtectedRoute from "./components/utilities/protectedRoute";
import loadable from "@loadable/component";
import { getConfig } from "./reducers/siteConfig";
import { getUser } from "./reducers/user";
import { StateType } from "./reducers";
import { ISiteConfig } from "../server/models/siteConfig.js";
import Notification from "./components/notification/notification";

const Articles = loadable(() => import("./components/pages/articles"));
const Article = loadable(() => import("./components/pages/article"));
const Store = loadable(() => import("./components/pages/store"));
const Project = loadable(() => import("./components/pages/project"));
const CheckoutComplete = loadable(() =>
  import("./components/pages/checkoutComplete")
);
const Contact = loadable(() => import("./components/pages/contact"));
const Policies = loadable(() => import("./components/pages/policies"));
const Cart = loadable(() => import("./components/pages/cart/cart"));
const Reset = loadable(() => import("./components/pages/reset"));
const Projects = loadable(() => import("./components/pages/projects"));
const MyAccount = loadable(() =>
  import("./components/pages/myAccount/myAccount")
);
const Product = loadable(() => import("./components/pages/product"));
//import "./App.css";
export type MatchType = {
  params: MatchPramsType;
  url: string;
};
export type MatchPramsType = {
  id: string;
};
type AppProps = {
  config: ISiteConfig;
  loading: boolean;
  getConfig: (overRide: boolean) => void;
};
const App = (props: AppProps) => {
  useEffect(() => {
    if (!props.loading && !props.config) {
      props.getConfig(false);
    }
  });
  const renderNotifications = () => {
    if (
      props.config !== null &&
      props.config.notifications &&
      props.config.notifications.length > 0
    ) {
      return (
        <Notification
          //@ts-ignore
          notification={props.config.notifications[0]}
          //@ts-ignore
          fullWidth={true}
        />
      );
    }
  };
  return (
    <div>
      <ScrollToTop></ScrollToTop>
      {renderNotifications()}
      <Navigation />
      <div className="spacer top" />
      <div className="wrapper">
        <div className="content main-site">
          <Switch>
            <Route exact={true} path="/" component={Store} />
            <Route path="/help/:id" component={Article} />
            <Route path="/help" component={Articles} />
            <Route path="/store/:id" component={Product} />
            <Route path="/store" component={Store} />
            <Route path="/projects/:id" component={Project} />
            <Route path="/projects" component={Projects} />
            <Route path="/cart" component={Cart} />
            <Route path="/order" component={CheckoutComplete} />
            <Route path="/support" component={Contact} />
            <Route path="/policies" component={Policies} />
            <Route path="/reset/:id/:hash" component={Reset} />
            <ProtectedRoute path="/my-account" component={MyAccount} />
          </Switch>
        </div>
      </div>

      <Footer />
    </div>
  );
};
const mapStateToProps = (state: StateType) => {
  return {
    config: state.config.config,
    loading: state.config.loading,
  };
};

export default connect(mapStateToProps, {
  getConfig,
})(App);
