import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import "./login.sass";
import ShortText from "../../utilities/inputs/shortText/shortText";
import { login, register } from "../../../reducers/user";
import Button from "../../utilities/buttons/button";
import logo from "../../../images/brand/boardsource_logo.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { lockScroll } from "../../../helpers";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "", forgot: false };
  }
  forgotPassword = () => {
    this.setState({ forgot: true });
  };

  sendForgotPassword = () => {
    axios
      .put("api/users/forgotpassword", { email: this.state.email })
      .then((res) => {
        toast.info(`Check ${this.state.email} for further instructions`, {
          position: "top-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .catch((error) => {
        toast.info(`Check your email`, {
          position: "top-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

  changeHandler = (name, newInfo) => {
    this.setState({ [name]: newInfo });
  };
  checkLogin = () => {
    const { email, password } = this.state;
    return email && email !== " " && password && password !== " ";
  };
  componentWillReceiveProps(nextprops) {
    if (nextprops.isAuthenticated) {
      this.props.close();
    }
  }
  login = () => {
    if (this.checkLogin()) {
      lockScroll(false);
      this.props.login({
        email: this.state.email,
        password: this.state.password,
      });
    }
  };

  renderLoginButton = () => {
    if (this.checkLogin()) {
      return (
        <Button
          style="outline"
          color="green"
          text="Login"
          function={this.login}
          filled={false}
          type="function"
        />
      );
    } else {
      return (
        <Button
          style="outline"
          color="red"
          text="Fields Required"
          function={() =>
            toast.error(`One or more fields are missing`, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
          }
          filled={false}
          type="function"
        />
      );
    }
  };
  renderMainInputsOrForgotInputs = () => {
    if (this.state.forgot) {
      return (
        <>
          <div className="login__main__inputs__email">
            <ShortText
              required={true}
              type="email"
              value={this.state.email}
              name="email"
              label="email"
              placeholder="email"
              labelHidden={true}
              fillField={this.changeHandler}
              field={"email"}
            />
          </div>

          <Button
            style="outline"
            color="green"
            text="Lets Change it"
            function={this.sendForgotPassword}
            filled={false}
            type="function"
          />
        </>
      );
    } else {
      return (
        <>
          <div className="login__main__inputs__email">
            <ShortText
              required={true}
              value={this.state.email}
              type="email"
              name="email"
              label="email"
              placeholder="E-Mail"
              labelHidden={true}
              fillField={this.changeHandler}
              field={"email"}
            />
          </div>
          <div className="login__main__inputs__pass">
            <ShortText
              required={true}
              value={this.state.password}
              type="password"
              name="password"
              label="password"
              placeholder="Password"
              labelHidden={true}
              fillField={this.changeHandler}
              field="password"
            />
          </div>
          <button
            className="login__main__inputs__forgot"
            onClick={this.forgotPassword}
          >
            forgot password
          </button>
          {this.renderLoginButton()}
          <button
            className="login__main__inputs__create"
            onClick={this.props.switchModal}
          >
            Create New Account
          </button>
        </>
      );
    }
  };
  render() {
    return (
      <div className="login">
        <div className="login__main">
          <img src={logo} alt="Boardsource Logo" />
          <div className="login__main__inputs">
            {this.renderMainInputsOrForgotInputs()}
          </div>
          {/* <div className="login__main__with">
            <p>Login Using</p>
            <a href="/auth/google">
              <button> Login With Google</button>
            </a>
            <a href="/auth/github">
              <button> Login With Github</button>
            </a>
            <div className="login__main__with__create">
              <Button
                style="outline"
                color="purple"
                text="CreateNewAccount"
                function={this.props.switchModal}
                filled={false}
                type="function"
              />
            </div>
          </div> */}
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  error: state.error,
});

export default connect(mapStateToProps, { login, register })(Login);
