import React, { Component } from "react";
import CreateNewAccount from "./createNewAccount/createNewAccount";
import Login from "./login/login";
import { Transition } from "react-transition-group";

class AuthModal extends Component {
  constructor(props) {
    super(props);
    this.state = { create: false, defaultWidth: null };
    this.containerRef = React.createRef();
  }
  componentDidMount() {
    this.setState({ defaultWidth: this.containerRef.current.offsetWidth });
    window.addEventListener("resize", this.getWidth);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.getWidth());
  }
  getWidth = () => {
    this.setState({ defaultWidth: null }, () => {
      this.setState({ defaultWidth: this.containerRef.current.offsetWidth });
    });
  };

  switchModal = () => this.setState({ create: !this.state.create });
  render() {
    const duration = 500;
    const defaultStyle = {
      transition: `all ${duration}ms ease-in-out`,
      // position: "absolute",
      // top: 50,
      // right: 50,
      // transform: "translate(-300%)"
    };

    const transitionStyles = {
      entering: { transform: "translate(-300%)" },
      entered: { transform: "translate(0)" },
      exiting: { transform: "translate(300%)" },
      exited: { transform: "translate(300%)" },
    };
    return (
      <div
        ref={this.containerRef}
        className="login__modal"
        style={{
          maxWidth: this.state.defaultWidth,
          display: "flex",
          justifyContent: "center",
          // minHeight: 300,
          // minWidth: 200,
          // position: "relative",
        }}
      >
        <Transition
          in={this.state.create}
          timeout={duration}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <CreateNewAccount
                switchModal={this.switchModal}
                close={this.props.close}
              />
            </div>
          )}
        </Transition>
        <Transition
          in={!this.state.create}
          timeout={duration}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <Login switchModal={this.switchModal} close={this.props.close} />
            </div>
          )}
        </Transition>
      </div>
    );
  }
}

export default AuthModal;
