import React, { Component } from "react";

import "./modalWrapper.sass";

class ModalWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // componentWillMount() {
  //   this.styleModalPosition();
  // }
  // componentDidMount() {
  //   console.log(this.props);
  //   window.addEventListener("resize", () => this.styleModalPosition());
  // }
  // styleModalPositoin = () => {
  //   const scrollPosition = window.scrollTop;
  //   this.setState({ modalTop: scrollPosition });
  // };
  render() {
    return (
      <div
        className={`modal__wrapper ${this.props.modalType} ${
          this.props.modalType === "full" ? "content " : ""
        }`}
        //stops click event that was on overlay
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal__wrapper__content">
          <div className="close__button">
            <button
              onClick={this.props.toggleModal}
              aria-label="Close Modal Window"
            >
              <img
                className="modal-close"
                src={this.props.modalClose}
                alt="x close icon"
              />
            </button>
          </div>

          {/* <>{this.props.children}</> */}
          <div style={{ height: "100%" }}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default ModalWrapper;
