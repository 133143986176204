import React, { Component } from "react";
import "../button.sass";

var classNames = require("classnames");

class ButtonContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageHovered: false,
      open: false,
    };
  }
  renderButtonText = (button, styles) => {
    return (
      // <div style={{ display: "table" }}>
      // <div style={{ display: "table-cell", verticalAlign: "middle" }}>
      <>
        {this.props.text}
        {this.props.icon ? (
          <div className={styles}>
            <img src={this.props.icon} alt="icon alt" />
          </div>
        ) : (
          ""
        )}
      </>
      // </div>
      // </div>
    );
  };
  renderButtonIcon = (button) => {
    return (
      <div
        className="button__icon"
        style={{ transform: `scale(${this.props.scale})` }}
        onMouseEnter={() => this.setState({ imageHovered: true })}
        onMouseLeave={() => this.setState({ imageHovered: false })}
      >
        {this.state.imageHovered && this.props.imageSrcHover ? (
          <div
            className={`button__icon__hovered ${
              this.state.imageHovered ? "active" : "inactive"
            }`}
          >
            <img src={this.props.imageSrcHover} alt={this.props.imageAlt} />
          </div>
        ) : (
          <div
            className={`button__icon__normal ${
              !this.state.imageHovered ? "active" : "inactive"
            }`}
          >
            <img src={this.props.imageSrc} alt={this.props.imageAlt} />
          </div>
        )}
      </div>
    );
  };
  handleClick = (props) => {
    if (props.type === "function" && !this.props.inactive) {
      if (props.selectOne && props.argument) {
        return this.props.function(props.selected, props.argument);
      } else if (props.selectOne && !props.argument) {
        return this.props.function(props.selected);
      } else if (!props.selectOne) {
        return this.props.function(props.argument);
      } else if (this.props.inactive) {
        return;
      } else {
        return this.props.function();
      }
    } else {
    }
  };

  render() {
    var btnClass = classNames({
      button: true,
      "full-width": this.props.fullWidth,
      "btn-icon": this.props.icon,
      "btn-function": this.props.type === "function",
      "btn-inactive":
        this.props.selectOne && this.props.activeButton !== this.props.selected,
      "btn-filled": this.props.filled,
      "btn-active":
        this.props.selectOne && this.props.activeButton === this.props.selected,
      "filled-purple":
        this.props.filled && this.props.color === "purple" && this.props.text,
      "filled-blue": this.props.filled && this.props.color === "blue",
      "filled-green": this.props.filled && this.props.color === "green",
      "filled-yellow":
        this.props.filled && this.props.color === "yellow" && this.props.text,
      "filled-red": this.props.filled && this.props.color === "red",
      "outline-purple":
        this.props.style === "outline" &&
        this.props.color === "purple" &&
        !this.props.selectOne,
      "outline-blue":
        this.props.style === "outline" &&
        this.props.color === "blue" &&
        !this.props.selectOne,
      "outline-green":
        this.props.style === "outline" &&
        this.props.color === "green" &&
        !this.props.selectOne,
      "outline-yellow":
        this.props.style === "outline" &&
        this.props.color === "yellow" &&
        !this.props.selectOne,
      "outline-red":
        this.props.style === "outline" &&
        this.props.color === "red" &&
        !this.props.selectOne,
      "outline-dark":
        this.props.style === "outline" &&
        this.props.color === "dark" &&
        !this.props.selectOne,
      "outline-light-grey":
        this.props.style === "outline" &&
        this.props.color === "light-grey" &&
        !this.props.selectOne,
      // "icon-purple": this.props.icon && this.props.iconBackground === "purple",
      // "icon-blue": this.props.icon && this.props.iconBackground === "blue",
      // "icon-green": this.props.icon && this.props.iconBackground === "green",
      // "icon-yellow": this.props.icon && this.props.iconBackground === "yellow",
      // "icon-red": this.props.icon && this.props.iconBackground === "red",
      // "icon-dark": this.props.icon && this.props.iconBackground === "dark",
      // "icon-light-grey":
      //   this.props.icon && this.props.iconBackground === "light-grey",

      "text-only": !this.props.text,
      blank: this.props.style === "none",
      inactive: this.props.inactive,
    });
    var iconStyles = classNames({
      icon: true,
      "icon-purple":
        this.props.color === "purple" && !this.props.iconBackground,
      "icon-blue": this.props.color === "blue" && !this.props.iconBackground,
      "icon-green": this.props.color === "green" && !this.props.iconBackground,
      "icon-yellow":
        this.props.color === "yellow" && !this.props.iconBackground,
      "icon-red": this.props.color === "red" && !this.props.iconBackground,
      "icon-dark": this.props.color === "dark" && !this.props.iconBackground,
      "icon-light-grey":
        this.props.color === "light-grey" && !this.props.iconBackground,
      "icon-diff-purple":
        this.props.iconBackground && this.props.iconBackground === "purple",
      "icon-diff-blue":
        this.props.iconBackground && this.props.iconBackground === "blue",
      "icon-diff-green":
        this.props.iconBackground && this.props.iconBackground === "green",
      "icon-diff-yellow":
        this.props.iconBackground && this.props.iconBackground === "yellow",
      "icon-diff-red":
        this.props.iconBackground && this.props.iconBackground === "red",
      "icon-diff-dark":
        this.props.iconBackground && this.props.iconBackground === "dark",
      "icon-diff-light-grey":
        this.props.iconBackground && this.props.iconBackground === "light-grey",
    });
    return (
      <div
        className={`button__container ${!this.props.text ? "no-text" : ""}`}
        onClick={() => {
          this.handleClick(this.props);
        }}
      >
        <div className={btnClass} onClick={this.props.toggleModal}>
          <button
            aria-label={this.props.text ? this.props.text : this.props.ariaText}
            disabled={this.props.disabled}
            type={this.props.submit ? "submit" : "button"}
          >
            {this.props.style === "icon"
              ? this.renderButtonIcon(this.props)
              : ""}
            {this.props.text
              ? this.renderButtonText(this.props, iconStyles)
              : ""}
          </button>
          {this.props.children && <>{this.props.children}</>}
        </div>
        {this.props.filled ? (
          <div className={`${this.props.color} animation-overlay`}></div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ButtonContent;
