import React, { Component } from "react";

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="nav__content__mobile">
        <button
          className="hamburger-button"
          aria-label="Open Navigation Menu"
          onClick={() => {
            this.props.openMenu();
          }}
        >
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </button>
      </div>
    );
  }
}

export default Mobile;
