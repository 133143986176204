import axios from "axios";
import { toast } from "react-toastify";
import { merge } from "lodash";
import {
  GET_PRODUCTS,
  PRODUCTS_LOADING,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  GET_PRODUCT,
  DELETE_PRODUCT,
  GOT_ERR,
} from "./types";

import { userToken } from "./user";
import { overViewArray } from "../helpers";
import { DispatchType, GetStateType, ActionType } from ".";
import { IProduct } from "../../server/models/products";
let productMap = new Map();
export type ProductStateType = {
  products: IProduct[];
  product: IProduct;
  loading: boolean;
  msg: string;
  status: string | null;
  success: string | null;
  err: any;
};

export const productInitialState: ProductStateType = {
  products: [],
  product: null,
  loading: false,
  msg: "",
  status: null,
  success: null,
  err: {},
};
const gotProducts = (newProducts: IProduct[]) => {
  if (productMap.size < 1) {
    productMap = newProducts.reduce(function (map, obj) {
      map.set(obj._id, obj);
      return map;
    }, productMap);
  }

  return newProducts.map((product: IProduct) => {
    if (productMap.has(product._id)) {
      const productFromMap = productMap.get(product._id);
      if (productFromMap.hasOwnProperty("full") && productFromMap.full) {
        const combined = merge(productFromMap, product);
        return combined;
      } else {
        //@ts-ignore
        if (product.hasOwnProperty("full") && product.full) {
          productMap.set(product._id, product);
        }
        return product;
      }
    } else {
      return product;
    }
  });
};
export const getProducts = () => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  if (!getState().product.loading) {
    dispatch(setProductsLoading());
    axios
      .get("/api/products")
      .then((res) => {
        dispatch({
          type: GET_PRODUCTS,
          payload: res.data.products,
        });
      })
      .catch((error) => {
        console.log("I got an error in get products", error);
        if (error && error.response && error.response.data) {
          dispatch(
            gotErr(null, error.response.status, null, error.response.data.error)
          );
        }
      });
  }
};
export const getAllProducts = () => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  dispatch(setProductsLoading());
  axios
    .get("/api/products/all", userToken(getState))
    .then((res) => {
      dispatch({
        type: GET_PRODUCTS,
        payload: res.data.products,
      });
    })
    .catch((error) => {
      console.log("in get products", error);
      if (error && error.response && error.response.data) {
        dispatch(
          gotErr(null, error.response.status, null, error.response.data.error)
        );
      }
    });
};
export const createProduct = (
  product: IProduct,
  cb: (res: any, error: any) => void
) => (dispatch: DispatchType, getState: GetStateType) => {
  dispatch(setProductsLoading());
  axios
    .post("/api/products", product, userToken(getState))
    .then((res) => {
      if (cb) {
        cb(res, null);
      }
      dispatch({
        type: ADD_PRODUCT,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (cb) {
        cb(null, error);
      }
      if (error && error.response && error.response.data) {
        dispatch(
          gotErr(null, error.response.status, null, error.response.data.error)
        );
      }
    });
};

export const deleteProduct = (id: string) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  console.log(`deleting product:${id}`);
  dispatch(setProductsLoading());
  axios
    .delete(`/api/products/${id}`, userToken(getState))
    .then((res) =>
      dispatch({
        type: DELETE_PRODUCT,
        payload: id,
      })
    )
    .catch((error) => {
      if (error && error.response && error.response.data) {
        dispatch(
          gotErr(null, error.response.status, null, error.response.data.error)
        );
      }
    });
};

export const getProduct = (id: string) => (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  dispatch(setProductsLoading());
  const state = getState();
  const product =
    state.product.hasOwnProperty("products") &&
    state.product.products.length > 0
      ? state.product.products.find((p) => p._id === id)
      : false;
  //@ts-ignore
  if (product && product.hasOwnProperty("full") && product.full) {
    dispatch({
      type: GET_PRODUCT,
      payload: product,
    });
  } else {
    axios
      .get(`/api/products/${id}`)
      .then((res) => {
        const product = {
          ...res.data,
          overView: overViewArray(res.data, false),
        };
        dispatch({
          type: GET_PRODUCT,
          payload: product,
        });
      })
      .catch((error) => {
        console.log("error in get product : ", error);
        if (error && error.response && error.response.data) {
          dispatch(
            gotErr(
              error.response.message,
              error.response.status,
              null,
              error.response.data.error
            )
          );
        }
      });
  }
};

export const editProduct = (
  product: IProduct,

  cb: (res: any, error: any) => void
) => (dispatch: DispatchType, getState: GetStateType) => {
  dispatch(setProductsLoading());
  axios
    .patch(`/api/products/${product._id}`, product, userToken(getState))
    .then((res) => {
      if (cb) {
        cb(res, null);
      }
      dispatch({ type: EDIT_PRODUCT, payload: product });
    })
    .catch((error) => {
      if (cb) {
        cb(null, error);
      }
      if (error && error.response && error.response.data) {
        dispatch(
          gotErr(null, error.response.status, null, error.response.data.error)
        );
      }
    });
};

export const updateUsersAboutProduct = (
  id: string,
  subject: string,
  message: string
) => (getState: GetStateType) => {
  axios
    .post(
      `/api/orders/update/${id}`,
      { subject, body: message },
      userToken(getState)
    )
    .then((res) => {
      toast.info("mass Email Sent", {
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    })
    .catch((error) => {
      console.log("there was a problem sending emails", error);
      toast.error("error sending email", {
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    });
};

export const gotErr = (msg, status, success, err) => {
  return {
    type: GOT_ERR,
    payload: { msg, status, success, err },
  };
};

export const setProductsLoading = () => ({ type: PRODUCTS_LOADING });

export default (state = productInitialState, action: ActionType) => {
  switch (action.type) {
    case GET_PRODUCTS: {
      const products = gotProducts(action.payload);
      return {
        ...state,
        products,
        loading: false,
      };
    }
    case GET_PRODUCT: {
      const products = gotProducts(
        state.products.map((product) =>
          product._id === action.payload._id
            ? { ...action.payload, full: true }
            : product
        )
      );
      return {
        ...state,
        products,
        product: action.payload,
        loading: false,
      };
    }
    case PRODUCTS_LOADING:
      return { ...state, loading: true };
    case DELETE_PRODUCT:
      return {
        ...state,
        product: {},
      };
    case EDIT_PRODUCT: {
      return {
        ...state,
        product: action.payload,
      };
    }
    case ADD_PRODUCT: {
      return {
        ...state,
        product: action.payload,
        loading: false,
      };
    }
    case GOT_ERR:
      return {
        ...state,
        msg: action.payload.msg,
        status: action.payload.status,
        success: action.payload.success,
        err: action.payload.err,
      };
    default:
      return state;
  }
};
