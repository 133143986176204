import axios from "axios";
import { toast } from "react-toastify";
import { userToken } from "./user";
import { UPDATE_PROGRESS, END_UPLOAD, START_UPLOAD } from "./types";
import { needToLoginMessage } from "../helpers";
import { DispatchType, GetStateType, ActionType } from ".";
export type ImageStateType = {
  isUploading: boolean;
  progress: number;
};
export const imageInitialState: ImageStateType = {
  isUploading: false,
  progress: 0,
};

export const uploadImage = (
  file: any,
  fileName: string,
  fileType: string
) => async (dispatch: DispatchType, getState: GetStateType) => {
  dispatch(startUpload());
  try {
    const res = await axios.post(
      "/api/upload/image/request",
      JSON.stringify({ fileName, fileType }),
      userToken(getState)
    );
    const returnData = res.data.data.returnData;
    var signedRequest = returnData.signedRequest;
    var options = {
      headers: {
        "Content-Type": fileType,
      },
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.lengthComputable) {
          if (progressEvent.total) {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            dispatch(updateProgress(percent));
          }
        }
      },
    };

    const putResult = await axios.put(signedRequest, file, options);
    console.log("aws came in", putResult);
    dispatch(endUpload());
    console.log("resdata", returnData);
    return returnData.fileName;
  } catch (error) {
    console.log("upload error", error);
    if (error.response.status === 401) {
      toast.error(needToLoginMessage, {
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    if (error.response && error.response.data) {
      console.error(error.response.data, error.response.status);
    }
  }
};

export const startUpload = () => ({ type: START_UPLOAD });

export const updateProgress = (percent: number) => ({
  type: UPDATE_PROGRESS,
  payload: percent,
});

export const endUpload = () => ({ type: END_UPLOAD });

export default (state = imageInitialState, action: ActionType) => {
  switch (action.type) {
    case START_UPLOAD:
      return { ...state, isUploading: true, progress: 0 };
    case END_UPLOAD:
      return { ...state, isUploading: false, progress: 100 };
    case UPDATE_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    default:
      return state;
  }
};
