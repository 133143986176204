import React, { Component } from "react";
import RToggle from "react-toggle";
import "react-toggle/style.css";
class Toggle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <label
        className={this.props.className}
        style={
          {
            // flexDirection: "column",
            // alignItems: "flex-end",
            // justifyContent: "flex-end",
          }
        }
      >
        {this.props.hideLabel ? "" : this.props.label}
        <span>
          <RToggle
            name={this.props.name}
            checked={this.props.value}
            aria-labelledby={this.props.label}
            onChange={this.props.onChange}
          />
        </span>
      </label>
    );
  }
}

export default Toggle;
