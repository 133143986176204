import React, { Component } from "react";
import PropTypes from "prop-types";
import Required from "../../required/required";
import "../inputs.sass";
class ShortText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: ""
    };
  }
  handleChange = e => {
    // this.setState({ inputValue: e.target.value });
    process.nextTick(this.updateParent);
    this.props.fillField(this.props.field, e.target.value);
  };
  updateParent = () => {};

  renderRequired = () => {
    if (this.props.required) {
      return (
        <div className="short__text__required">
          <input
            required
            value={this.props.value}
            type={this.props.type}
            name={this.props.name}
            onChange={this.handleChange}
            placeholder={this.props.placeholder}
          />
          <Required field={this.props.value} />
        </div>
      );
    } else {
      return (
        <input
          value={this.props.value}
          type={this.props.type}
          name={this.props.name}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
        />
      );
    }
  };
  render() {
    return (
      <div className="short__text">
        <label>
          <span className={this.props.labelHidden ? "visually-hidden" : ""}>
            {this.props.label}:
          </span>
          {this.renderRequired()}
        </label>
      </div>
    );
  }
}

ShortText.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labelHidden: PropTypes.bool.isRequired
};
export default ShortText;
