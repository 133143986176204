import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getUser } from "../../reducers/user";

class ProtectedRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  auth = () => {
    this.props.getUser();

    if (this.props.adminNeeded === true) {
      if (this.props.user.accountLevel === "vapeGod") {
        return true;
      } else {
        return false;
      }
    }
    if (this.props.user === null || Object.keys(this.props.user).length === 0) {
      return false;
    }
    if (this.props.isAuthenticated) {
      return true;
    } else if (localStorage.getItem("token") && !this.props.didTry) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { component: Comp, path, ...rest } = this.props;
    return (
      <Route
        path={path}
        {...rest}
        render={(props) => {
          return this.auth() ? <Comp {...props} /> : <Redirect to="/" />;
        }}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user.currentUser,
  isAuthenticated: state.user.isAuthenticated,
  didTry: state.user.didTry,
});
export default connect(mapStateToProps, { getUser })(ProtectedRoute);
