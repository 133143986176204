import axios from "axios";
import moment from "moment";
import { v1 as uuidv1 } from "uuid";
import { IOrder } from "../server/models/orders";
import { IProduct } from "../server/models/products";
import { IProject } from "../server/models/projects";
import { IUser } from "../server/models/user";
import { CartItemType } from "./reducers/cart";

export const isTypeOfProject = (obj: any): obj is IProject => {
  return "designers" in obj;
};
export const isTypeOfOrder = (obj: any): obj is IOrder => {
  return "packingList" in obj;
};

export const isTypeOfUser = (obj: any): obj is IUser => {
  return "password" in obj;
};

export const isTypeOfProduct = (obj: any): obj is IProduct => {
  if (typeof obj === "object") {
    return "main" in obj;
  } else {
    return false;
  }
};
export const overViewArray = (
  project: IProduct | IProject,
  isProject: boolean
) => {
  // put more elements in this array if you want them to be in the overview display
  const projectTags = [
    { type: ["keycap Set"], displayName: "Kit Contents", field: "keycapKits" },
    { type: ["keycap Set"], displayName: "Profile", field: "profile" },
    {
      type: ["keycap Set", "Case", "Misc/Other"],
      displayName: "Material",
      field: "material",
    },
    {
      type: ["keycap Set", "Case", "Misc/Other"],
      displayName: "Order Limit",
      field: "orderLimit",
    },
    {
      type: ["keycap Set", "Case", "Misc/Other"],
      displayName: "Color",
      field: "projectColorFamily.general",
    },
  ];
  const productTags = [
    {
      type: ["keycap Set", "Case", "Misc/Other"],
      displayName: "Color",
      field: "projectColorFamily.general",
    },
    {
      type: ["keycap Set", "Case", "Misc/Other"],
      displayName: "Kit Available",
      field: "otherProductsNeededToBuild",
      arrField: "product.name",
    },
  ];

  // for loop just because perf
  if (isTypeOfProject(project) && isProject) {
    // change the default included ones here
    let projectOverViewTags = [
      { name: "Product", content: project.type },
      {
        name: "Designer",
        content: project.designers
          .filter((d) => d.name !== "")
          .map((d) => `${d.name} : ${d.product}`),
      },
    ];
    for (let i = 0; i < projectTags.length; i++) {
      const projectTag = projectTags[i];
      if (
        projectTag.type.length === 0 ||
        projectTag.type.includes(project.category)
      ) {
        const toBeAssignedValue = Array.isArray(project[projectTag.field])
          ? project[projectTag.field].map((f) => f.type)
          : fetchFromObject(project, projectTag.field);
        const newOverViewItem = {
          name: projectTag.displayName,
          content: toBeAssignedValue,
        };
        //todo dirty trick refactor
        const isOrderLimit =
          newOverViewItem.name === "Order Limit" &&
          newOverViewItem.content === 0;
        if (notEmpty(toBeAssignedValue) && !isOrderLimit) {
          projectOverViewTags.push(newOverViewItem);
        }
      }
    }

    return projectOverViewTags;
  } else {
    let productOverViewTags = [{ name: "Product", content: project.type }];
    /*    if (project.designers && project.designers[0]) {*/
    //productOverViewTags.push({
    //name: "Designer",
    //content: project.designers[0].name,
    //});
    /*}*/
    for (let i = 0; i < productTags.length; i++) {
      const productTag = productTags[i];
      if (
        productTag.type.length === 0 ||
        productTag.type.includes(project.category)
      ) {
        productOverViewTags.push({
          name: productTag.displayName,
          content: Array.isArray(project[productTag.field])
            ? project[productTag.field].map((f) =>
              fetchFromObject(f, productTag.arrField)
            )
            : fetchFromObject(project, productTag.field),
        });
      }
    }

    return productOverViewTags;
  }
};
export const stringDot = (obj: object, i: string) => obj[i];
export const toUrlImg = (img: string) => "https://boardsource.imgix.net/" + img;
export const insertIntoArray = (
  arr: Array<any>,
  index: number,
  newItem: any
): Array<any> => [...arr.slice(0, index), newItem, ...arr.slice(index)];

export const reorderArray = (
  list: Array<any>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
export const safeString = (str: string | undefined) =>
  str ? str.split(" ").join("-") : "UNDEFINED";
export const unsafeString = (str: string | undefined) =>
  str ? str.split("-").join(" ") : "UNDEFINED";

export const pingView = (id) => {
  axios
    .post(`/api/projects/view/${id}`)
    .catch((error) =>
      console.log(error, "err letting the server know about a view")
    );
};
export const projectForProjects = (
  id: string,
  projects: Array<IProduct | IProject>
) => projects.find((p) => p._id === id);

export const chartColors = [
  "#6fffe9",
  "#434366",
  "#845eff",
  "#f8c779",
  "rgb(159, 231, 189)",
  "#3499DC",
  "#2FCC72",
  "#F2C311",
  "#e64d3c",
  "#6FFFE9",
  "#383838",
  "#656565",
];

export const fetchFromObject = (obj, prop) => {
  if (typeof obj === "undefined") {
    return false;
  }
  let index = prop.indexOf(".");
  if (index > -1) {
    return fetchFromObject(
      obj[prop.substring(0, index)],
      prop.substr(index + 1)
    );
  }
  return obj[prop];
};

export const calculatePriceHigh = (product: IProduct) => {
  if (product.otherProductsNeededToBuild === undefined) {
    return;
  }
  const priceArray = product.otherProductsNeededToBuild
    .map((p) => {
      if (isTypeOfProduct(p.product)) {
        return { type: p.product.type, price: p.product.price };
      }
    })
    .filter((p) => p !== undefined);
  let totalObject = { starting: 0 };
  totalObject.starting = product.price;
  priceArray.forEach((p) => {
    if (p.type === "Misc Part") {
      totalObject[uuidv1()] = p.price;
    } else if (p.type === "Other") {
      return;
    } else if (!totalObject[p.type] || totalObject[p.type] < p.price) {
      totalObject[p.type] = p.price;
    } else {
      return;
    }
  });
  let total = 0;
  Object.values(totalObject).forEach((p) => {
    total += p;
  });
  return showPriceAsUsd(total);
};

export const showPriceAsUsd = (num: number) => {
  const price = num.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (price.endsWith(".00")) {
    return `$${num}`;
  } else {
    return price;
  }
};
export const isInStock = (product: IProduct) => {
  if (!product) {
    return false;
  }
  if (product.quantityInStock > 0) {
    return true;
  } else {
    if (
      product.variations &&
      product.variations.length > 0 &&
      product.variations.some((item) => {
        if (isTypeOfProduct(item.product)) {
          return item.product.quantityInStock > 0;
        } else {
          return false;
        }
      })
    ) {
      return true;
    } else if (
      product.otherProductsNeededToBuild &&
      product.otherProductsNeededToBuild.length > 0
    ) {
      return product.otherProductsNeededToBuild.some((item) => {
        if (
          isTypeOfProduct(item.product) &&
          item.product &&
          item.product.type !== "Misc Part" &&
          item.product.type !== "Cable"
        )
          return isInStock(item.product);
      });
    } else {
      return false;
    }
  }
};

export const returnItemStatus = (item: IProduct | IProject): string => {
  let status = "Interest Check";
  if (isTypeOfProject(item)) {
    //this is where project stuff goes
    status = item.projectFormat;
  } else if (item.hasOwnProperty("quantityInStock")) {
    if (item.hasOwnProperty("isGroupBuy") && item.isGroupBuy) {
      status = isInStock(item) ? "Group Buy" : "Just Ran Out";
    }
    status = isInStock(item) ? "In Stock" : "Just Ran Out";
  }
  return status;
};
// todo this one
export const calculateTotal = (cart, products: IProduct[]) => {
  // todo this bugs out fatty on my account views logs like 1000 things
  // console.log("being ran," + cart + products);
  const prices = {};
  products.forEach((product) => {
    prices[product._id] = product.price;
  });
  return cart.reduce((total, cartItem) => {
    const currentItemTotal = prices[cartItem.id] * cartItem.qty;
    return total + currentItemTotal;
  }, 0);
};
export const needToLoginMessage = "🦄 Oh No... You are going to need to login ";

export const formatDate = (date: Date) => {
  return moment(date).format("MM/DD/YY");
};

export const submitTicket = async (link, body, title, image, id, userId) => {
  return axios.post("/api/ticket/", { link, body, title, image, id, userId });
};

export const luluppp = async () => {
  return axios.get("/api/users/luluppp").then(res => {
    console.log("Im back no err", res)
  }).catch(err => {
    console.log("err luluppp", err)
  });
};

export const givePPP = async (email) => {
  return axios.post("/api/users/giveppp", { email }).then(res => {
    console.log("Im back no err", res)
  }).catch(err => {
    console.log("err givePPP", err)
  });
};


// export const formatAddressBlock = (addressObject) => {
//   return Object.keys(addressObject).map((l) => {

//   });
// };

export const productForProducts = (
  id: string,
  products: Array<IProduct | IProject>
) => {
  return products.find((p) => p._id === id);
};

export const primarySubForItem = (item) => {
  switch (item.category) {
    case "keycap Set":
      return { key: "profile", currentValue: item.profile };
    case "Case":
      return { key: "material", currentValue: item.material };
    case "Misc/Other":
      if (item.type === "Misc Part") {
        return { key: "lotSize", currentValue: item.lotSize };
      } else {
        return { key: "type", currentValue: item.type };
      }
  }
};
export const truncateString = (str, desiredLength) => {
  if (!str) {
    return "loading...";
  }
  const strLength = str.length;
  if (strLength < desiredLength || strLength - desiredLength < 3) {
    return str;
  } else {
    let finalStr = "";
    const words = str.split(" ");
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const wordLength = word.length;
      const finalLength = finalStr.length;
      const wouldBeTotalLength = wordLength + 1 + finalLength;

      const threeMoreThenWanted =
        wouldBeTotalLength > desiredLength
          ? wouldBeTotalLength - desiredLength < 3
          : false;
      const threeLessThenWanted =
        wouldBeTotalLength < desiredLength
          ? desiredLength - wouldBeTotalLength < 3
          : false;
      if (
        wouldBeTotalLength === desiredLength ||
        threeMoreThenWanted ||
        threeLessThenWanted
      ) {
        finalStr = `${finalStr} ${word}...`;
        break;
      } else if (wouldBeTotalLength < desiredLength) {
        finalStr = `${finalStr} ${word}`;
      } else {
        finalStr = `${finalStr}...`;
        break;
      }
    }
    return finalStr;
  }
};

export const returnUsdPrice = (number: number) =>
  Number(number / 100).toLocaleString();

export const retry = (fn, retriesLeft = 5, interval = 500) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        console.log("I am retrying ");
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};
export const gbInOrder = (
  order: IOrder,
  products: IProduct[]
): {
  containsGb: boolean;
  gbItem: IProduct | undefined;
  containsOthers: IProduct[] | undefined;
  otherGbItemsInOrder: IProduct[] | undefined;

} => {
  const productsInOrder = order.products.map((product) => {
    if (isTypeOfProduct(product.id)) {
      return product.id;
    } else {
      return productForProducts(`${product.id}`, products) as IProduct;
    }
  });
  const gb = productsInOrder.find((product) => product && product.isGroupBuy);
  const containsOthers =
    gb !== undefined
      ? productsInOrder.filter(
        (product) =>
          product &&
          product._id !== gb._id &&
          !JSON.stringify(gb).includes(product._id)
      )
      : undefined
  const otherGbItemsInOrder = containsOthers !== undefined && containsOthers.length > 0 ? containsOthers.filter(product => product._id !== gb._id && product.isGroupBuy) : undefined
  return {
    containsGb: gb !== undefined,
    gbItem: gb,
    containsOthers,
    otherGbItemsInOrder
  };
};

export const lockScroll = (bool: boolean) => {
  if (window !== undefined) {
    if (bool) {
      document.body.classList.add("modal-open");
    } else if (!bool) {
      document.body.classList.remove("modal-open");
    } else {
      return;
    }
  }
};

export const notEmptyObj = (obj) => {
  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).length > 0;
  } else {
    return true;
  }
};
export const notEmptyArray = (arr) => {
  if (Array.isArray(arr)) {
    return arr.length > 0;
  } else {
    return true;
  }
};

export const notEmptyStr = (str) => str && str !== "" && str !== " ";
export const notEmpty = (item) =>
  item && notEmptyStr(item) && notEmptyArray(item) && notEmptyObj(item);
export const calculateReadTime = (text) => {
  const wordsPerMinute = 200; // Average case.
  const textLength = text.split(" ").length; // Split by words
  if (textLength > 0) {
    let value = Math.ceil(textLength / wordsPerMinute);
    return `${value} Minutes`;
  }
};
export const isItemAProduct = (item) => {
  if (item.weight) {
    return true;
  } else {
    return false;
  }
};
export const checkForAssembly = (
  arrayOfProducts,
  needToFetch,
  fullProducts
) => {
  let products = arrayOfProducts;
  if (needToFetch) {
    products = arrayOfProducts.map((productId) =>
      fullProducts.find((product) => product._id === productId)
    );
  }
  return products.some(
    (product) => product && product.name && product.name.includes("oldering")
  );
};
export const populateCart = (cart, fullProducts) => {
  return cart.map((cartItem) => ({
    ...cartItem,
    id: fullProducts.find((product) => product._id === cartItem.id),
  }));
};
export const checkForCanNotAssemble = (
  arrayOfProducts,
  needToFetch,
  fullProducts
) => {
  let products = arrayOfProducts;
  if (needToFetch) {
    products = arrayOfProducts.map((productId) =>
      fullProducts.find((product) => product._id === productId)
    );
  }
  return products.some((product) => product && product.canNotAssemble);
};
export const checkKitMcu = (cart, fullProducts) => {
  if (!cart || !fullProducts) {
    return false;
  }
  const populatedCart = populateCart(cart, fullProducts);
  let kitsInCart = [];
  populatedCart.forEach((cartItem) => {
    if (cartItem.kitId) {
      if (kitsInCart.find((kit) => kit.kitId === cartItem.kitId)) {
        kitsInCart = kitsInCart.map((kit) =>
          kit.kitId === cartItem.kitId
            ? { ...kit, cartItems: [...kit.cartItems, cartItem] }
            : kit
        );
      } else {
        kitsInCart.push({
          kitId: cartItem.kitId,
          cartItems: [cartItem],
          mcuNeededCount: 0,
          mcuCount: 0,
        });
      }
    }
  });
  kitsInCart = kitsInCart.map((kit) => {
    const main = kit.cartItems.find((cartItem) => cartItem.id.type === "Pcb");
    const mcuNeededCount = main.id.otherProductsNeededToBuild
      .filter((itemNeededToBuild) => itemNeededToBuild.preSelected)
      .find(
        (itemNeededToBuild) =>
          itemNeededToBuild.product.name.startsWith("Elite") ||
          itemNeededToBuild.product.name.startsWith("Pro Micro")
      ).qtyNeeded;
    const mcuCount = populatedCart
      .filter(
        (cartItem) =>
          cartItem.id.name.startsWith("Elite") ||
          cartItem.id.name.startsWith("Pro Micro")
      )
      .reduce((acc, current) => acc + current.qty, 0);
    return { ...kit, mcuNeededCount, mcuCount };
  });
  // console.log("kits in cart", kitsInCart);
};
export const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);
export const gbInCart = (cart: CartItemType[], products: IProduct[]) => {
  const gbinCart = cart.find((cartItem) => {
    const mainProduct = products.find(product => product._id === cartItem.mainProductId)
    if (mainProduct) {
      return mainProduct.isGroupBuy
    } else {
      return false
    }
  })
  return gbinCart ? true : false
}
