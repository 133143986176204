import React, { Component } from "react";
import PropTypes from "prop-types";
import ButtonModal from "./buttonModal/buttonModal";
import ButtonLink from "./buttonLink/buttonLink.js";
import ButtonFunction from "./buttonFunction/buttonFunction.js";
import ButtonText from "./buttonText/buttonText.js";

import "./button.sass";

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colors: {
        purple: "#834eff",
        blue: "#4399DC",
        green: "#2FCC72",
        yellow: "#FC2311",
        red: "#E64D3C",
        black: "#0000",
      },
    };
  }
  buildButton = (props) => {
    switch (props.type) {
      case "modal":
        return <ButtonModal {...this.props} />;
        break;
      case "link":
        return <ButtonLink {...this.props} />;
        break;
      case "function":
        return <ButtonFunction {...this.props} />;
        break;
      case "text":
        return <ButtonText {...this.props} />;
        break;
    }
  };

  render() {
    return <>{this.buildButton(this.props)}</>;
  }
}

Button.propTypes = {
  // ariaText: PropTypes.string.isRequired
};

Button.defaultProps = {
  text: false,
  filled: true,
  color: "purple",
  link: false,
  linkPath: "/",
  linkAlt: "link alt text",
  modal: false,
  modalType: "auto",
  modalColor: "light",
  ModalContent: "test modal",
  scale: "1",
};

export default Button;
